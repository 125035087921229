import './blogs.scss';
import Carousel from './Caurosel/Carousel';
function Blogs(){
    return(
<>
<div id="blogs">
<Carousel/>
</div>
</>
    );
}


export default Blogs;