
import './founder.scss';

function founder(){
    return(<div id="founder">

<div className="container-fluid">
<div className="row">
    <div id="heading" className="col-md-12 col-sm-12 col-12">
        <h1>Our Founders</h1>
    </div>
    <div className="col-md-3 col-sm-3 col-12">
           
           <div id="box" className="hover-zoom bg-image"  >
               <img data-aos="fade-up" src="https://res.cloudinary.com/dpysmqax5/image/upload/v1616922167/akshit_wdadcm.png" className="hover-zoom bg-image"/>

               <h2> Akshit Dangi </h2>
               <p> Founder {"&"} Director</p>
               <table>
                   <tr>  
                       <td><a href="#" data-aos="flip-down" onClick={()=>{window.open("https://www.facebook.com/akshit.dangi.7")}} className="fa fa-facebook"></a></td>
                       <td><a href="#" data-aos="flip-down" onClick={()=>{window.open("https://www.instagram.com/akshit_____dangi__/")}} className="fa fa-instagram"></a></td>
                       <td><a href="#" data-aos="flip-down" onClick={()=>{window.open("https://www.linkedin.com/in/akshit-dangi-442855176/")}} className="fa fa-linkedin"></a></td>
                   </tr>
               </table>
           </div>
        

    </div>

    <div className="col-md-3 col-sm-3 col-12">
           
           <div id="box" >
               <img data-aos="fade-up" src="https://res.cloudinary.com/dpysmqax5/image/upload/v1616922165/amit_gqij6s.png" />

               <h2> Amit Bhati </h2>
               <p> Founder {"&"} Director</p>
               <table>
                   <tr>  
                       <td><a data-aos="flip-down" href="#" onClick={()=>{window.open("https://www.facebook.com/profile.php?id=100012608142889")}} className="fa fa-facebook"></a></td>
                       <td><a data-aos="flip-down" href="#" onClick={()=>{window.open("https://www.instagram.com/amybhati01/")}} className="fa fa-instagram"></a></td>
                       <td><a data-aos="flip-down" href="#" onClick={()=>{window.open("https://www.linkedin.com/in/amit-bhati-783963182/")}} className="fa fa-linkedin"></a></td>
                   </tr>
               </table>
           </div>
        

    </div>

    <div className="col-md-3 col-sm-3 col-12">
           
           <div id="box" >
               <img data-aos="fade-up" src="https://res.cloudinary.com/dpysmqax5/image/upload/v1616922163/Jeevant_vuay3o.jpg" />

               <h2> Jeevant Sah </h2>
               <p>       Founder {"&"} Director</p>
               <table>
                   <tr>
                   <td><a href="#" data-aos="flip-down" onClick={()=>{window.open("https://www.facebook.com/Jeevantsah")}} className="fa fa-facebook"></a></td>
                       <td><a href="#" data-aos="flip-down" onClick={()=>{window.open("https://www.instagram.com/jeevantsah/")}} className="fa fa-instagram"></a></td>
                       <td><a href="#" data-aos="flip-down" onClick={()=>{window.open("https://www.linkedin.com/in/jeevantsah/")}} className="fa fa-linkedin"></a></td>
              
                   </tr>
               </table>
           </div>
        

    </div>

    <div className="col-md-3 col-sm-3 col-12">
           
           <div id="box" >
               <img data-aos="fade-up" src="https://res.cloudinary.com/dpysmqax5/image/upload/v1616922088/lalit_ynvmj0.png" />

               <h2> Lalit </h2>
               <p>        Director {"&"} CTO</p>
               <table>
                   <tr>  
                   <td><a href="#" data-aos="flip-down" onClick={()=>{window.open("https://www.facebook.com/lalit.baghel.944")}} className="fa fa-facebook"></a></td>
                       <td><a href="#" data-aos="flip-down" onClick={()=>{window.open("https://www.instagram.com/baghel__lalit/")}} className="fa fa-instagram"></a></td>
                       <td><a href="#" data-aos="flip-down" onClick={()=>{window.open("https://www.linkedin.com/in/lalit-kumar-baghel-a80457141/")}} className="fa fa-linkedin"></a></td>
                   </tr>
               </table>
           </div>
        

    </div>
</div>
</div>






    </div> 

    );
}


export default founder;
