export const initialState = null;
export const items_number=localStorage.getItem("numberOfItems");

export const reducer = (state,action)=>{
    if(action.type == "USER"){
        return action.payload;
    }
    if(action.type == "item_add"){
        return action.items_number;
    }
    
    return state;
}