import Topheader from '../top_header/index';
import Footer from '../Footer/footer2';
import { Profiler, useState } from 'react';
import Man from './man2.png';
//import {  Loader } from 'react-overlay-loader';
//import LoadingOverlay from "react-loading-overlay";

import './profile.scss';
import axios from 'axios';
import React from 'react';
import Beacon from './beacon2.png';
import { UserContext } from '../../App';
//import 'react-overlay-loader/styles.css';
class Profile extends React.Component {

  state = {
    loader: true,
    user: [],
    wishlist: []
  };
  componentDidMount = () => {
    //console.log(new URLSearchParams(this.props.location.search).get("amount"));
    const script = document.getElementById("recaptcha-key");
    if (script) {
      script.remove();
    }
    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElems.length) {
      recaptchaElems[0].remove();
    }
    this.getDetail();
  }

  getDetail = () => {
    axios({
      url: 'https://api.scratchnest.com/orders',
      method: 'GET',
      withCredentials: true
      //data: payload
    })
      .then((res) => {
        console.log(res.data.user);
        const user = res.data.user;
        const wishlist = res.data.user.wishlist;
        this.setState({ wishlist: wishlist });
      }).catch((err) => {
        console.log(err);
        const { history } = this.props;
        //    history.push('/login');
        this.setState({ loader: false });

      });
  }

  addToCart(id) {
    try {
      console.log(id);
      fetch('https://api.scratchnest.com/moveToCart', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify({
          "id": id
        })

      }).then(res => res.json()).then(res => {
        console.log(res)
        if (res.status == true) {
          this.setState({ setShow: true })
        }
        this.getDetail();
      })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err)
    }
  }


  // this is the code watchlist array -----------------------------------------------------------------------------------------------------------------------------------------------

  Wishlist = (wishlist) => {
    if (wishlist.length) {
      return wishlist.map((wish, i) => (


        <div className="row" id="items">

          <div className="col-sm-4 col-md-4 col-5">

            <img src={wish.imageUrl} onClick={() => { this.props.history.push(wish.path) }} />
          </div>

          <div className="col-sm-8 col-md-8 col-7">
            <h1> {wish.title} </h1>
            <p> {wish.description}</p>
            <div id="desktop">

              <table>
                <tr>
                  <td>
                    {!wish.price == '' ?
                      <h2>Price:  <span id="span2">₹ {wish.price}  </span> <span> <del>{wish.price + (wish.price / 10)}  </del> </span> </h2> :
                      <h2>Available Soon</h2>}
                  </td>
                  <td>

                  </td>
                  <td>
                    {!wish.price == '' ?
                      <button className="btn btn-outline-secondary" onClick={() => this.addToCart(wish.productId)}> Move to Cart</button> : null}</td>
                </tr>
              </table>
            </div>
          </div>

          <div className="col-sm-0 col-md-0 col-12">
            <div id="phone">
              <table>
                <tr>

                  <td>

                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {!wish.price == '' ?
                      <h2>Price:  <span id="span2">₹ {wish.price}  </span> <span> <del>{wish.price + (wish.price / 10)}  </del> </span> </h2> :
                      <h2>Available Soon</h2>}
                  </td>
                </tr>
                <tr>
                  <td>
                    {!wish.price == '' ?
                      <button className="btn btn-outline-secondary" onClick={() => this.addToCart(wish.productId)}> Move to Cart</button> : null}</td>
                </tr>

              </table>
            </div>
          </div>

        </div>
      ))
    } else {
      return <p>No items in watchlist</p>
    }
  }
  // end of the code watchlist array  -----------------------------------------------------------------------------------------------------------------------------------------------



  render() {
    return (
      <>
        <Topheader />
        {/*<LoadingOverlay
          active={this.state.loader}
     
          spinner={true}
          text="Loading Watchlist..."
        >*/}
        <div id="watchlist">

          <div className="container-fluid out">
            <div className="row">
              {/*
              <div className="col-sm-4 col-md-4 col-12 " >

                <div id="left">
                    <i class="fa fa-user-circle-o" id="image" ></i>    
                  <br></br>    <br></br>


                  <div id="data">
                    <h1> {this.state.user.name} </h1>

                    <h2><i class="fa fa-map-marker"></i>  {this.state.State} , India </h2>

                    <hr></hr>
                    <h3> <i class="fa fa-phone"></i>{this.state.user.contact_no}</h3>

                    <h4><i class="fa fa-envelope"></i>{this.state.user.email}</h4>

                    <hr></hr>
             <h5 style={{"color":"black","fontWeight":"bold"}}> My Address:</h5>
                    <h5>{this.state.street_add}</h5>
                    <h5> {this.state.city}</h5>
                    <h5> PinCode-{this.state.pin}</h5>
                    <br></br>
                    <br></br>
                    <button className="btn btn-primary" onClick={()=>{this.props.history.push("/editprofile")}}>Edit Profile Details</button>
                  </div>
                </div>
              </div>
              */}

              <div className="col-sm-12 col-md-2 col-12 "></div>


              <div className="col-sm-12 col-md-8 col-12 right">

                <div id="list">

                  <div id="heading">
                    <h1> Your Wishlist </h1>
                  </div>
                  <br></br>


                  {/* sample code for item , later we will add an array here  ------------------------------------------------------------------*/}



                  {/*
                  <div className="row" id="items">

<div className="col-sm-4 col-md-4 col-5">

<img src={Beacon} />
</div>

<div className="col-sm-8 col-md-8 col-7">
    <h1> Tracking Beacon SN-TB101 </h1>
    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    <div id="desktop">
   
<table>
    <tr>
        <td>
<h2>Price:   <span id="span2">sdfsdf</span> <span>( <del>₹ 2620 </del>) </span> </h2>
</td>
<td>

</td>

<td>
<button className="btn btn-outline-danger" id="delete">  <i className="fa fa-trash" ></i>Delete</button></td>
</tr>
</table>
    </div>
    </div>

<div className="col-sm-0 col-md-0 col-12">
<div id="phone">
<table>
    <tr>
   
<td>

</td>
</tr>
    <tr>
        <td colSpan={2}>
<h2>Price: <span>( <del>₹ 2620 </del>) </span>  <span id="span2">₹ 2629</span>  </h2>
</td>
</tr>
<tr>
<td>
<button className="btn btn-outline-danger" id="delete">  <i className="fa fa-trash" ></i>Delete</button></td>
</tr>

</table>
</div>
</div>

    </div>

    <br></br>
*/}




                  {/*<button className="btn btn-outline-primary" onClick={ this.setState({display:true})}>Show More</button>*/}


                  {this.Wishlist(this.state.wishlist)}
                  {/* saple item fo product end herer ------------------------------------------------------------------*/}


                </div>













              </div>

            </div>
          </div>

        </div>

        {/*</LoadingOverlay>*/}
        <Footer />
      </>
    );
  }
}


export default Profile;
