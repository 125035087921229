import Topheader from "../top_header";
import axios from 'axios';
import Footer from '../Footer/footer2';
import './store.scss';
import Items from './items';
import OurItems from './Ouritems';
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import rfid from './rfid.png';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Form } from "react-bootstrap";
import beacon from './beacon.png';
import ambitag from './logger.png';
import iot from './image/img1.png';

function Store() {
    const { state, dispatch, state2, dispatch2 } = useContext(UserContext);
    var history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Store";
        getproduct()
        // removeRecaptcha()
    }, [])
    const [show2, setShow2] = useState(false);
    const [show1, setShow1] = useState(false);
    var [search, setSearch] = useState(false);
    var [search2, setSearch2] = useState(false);
    var [select, setSelect] = useState(false);

    var [text, setText] = useState('');
    var [selectedprod, setSelectedProd] = useState('');

    var [found, setFound] = useState(false);
    var [prods, setProds] = useState([]);
    const [products, setProducts] = useState(JSON.parse(localStorage.getItem('products')) || []);
    var [totalPrice, setTotalPrice] = useState(JSON.parse(localStorage.getItem('totalPrice')) || 0);
    const ShowAll = () => {
        window.scrollTo(0, 0);
        setSearch(false);
        setText('');
        setSearch(false);
        setSelect(false);
        setSearch2(false);

    }
    const handleChange = (x) => {
        window.scrollTo(0, 0);
        setSearch(true);
        setSearch2(true);
        setSelectedProd('');
        setSelect(false);

        setText(x);

        setFound(false);
    }

    // mODAL CODE SETTING IT CLOSING 

    const handleRedirecting = () => {
        setShow2(false);
    }

    async function addToCart(id) {
        try {
            console.log(id);
            var prod = prods.findIndex(objInItems => new String(objInItems._id).trim() == new String(id).trim());
            console.log(id);
            console.log(products);
            console.log(totalPrice)
            fetch('https://api.scratchnest.com/addToCart', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({
                    "id": id
                })

            }).then(res => res.json()).then(res => {
                console.log(res)
                if (res.status != true) {
                    console.log(id);
                    console.log("runnning lgout");
                    const isExisting = products.findIndex(objInItems => new String(objInItems._id).trim() == new String(id).trim());
                    console.log(isExisting)
                    //increment();
                    if (isExisting >= 0) {
                        products[isExisting].qty += 1
                        totalPrice = totalPrice + products[isExisting].price
                        setTotalPrice(totalPrice)
                        localStorage.setItem('totalPrice', JSON.stringify(totalPrice));

                    } else {
                        //localStorage.setItem("numberOfItems",state+1);
                        prods[prod].qty = 1
                        prods[prod]['productId'] = prods[prod]['_id']; // Assign new key
                        products.push(prods[prod]);
                        console.log(totalPrice);
                        totalPrice = totalPrice + prods[prod].price
                        setTotalPrice(totalPrice)
                        localStorage.setItem('totalPrice', JSON.stringify(totalPrice));
                    }
                    localStorage.setItem('products', JSON.stringify(products));
                    var length = JSON.parse(localStorage.getItem("products")).length
                    localStorage.setItem("numberOfItems", length);
                    dispatch2({ type: 'INCREMENT' });
                    //localStorage.setItem('total', totalPrice);
                } else {
                    var length = res.user.cart.items.length
                    localStorage.setItem("numberOfItems", length);
                    dispatch2({ type: 'INCREMENT' });
                }
                setShow2(true);
            })
                .catch(err => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err)
        }
    }

    async function addToWishlist(id) {
        try {
            fetch('https://api.scratchnest.com/addToWishlist', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({
                    "id": id
                })

            }).then(res => res.json()).then(res => {
                console.log(res)
                if (res.status == true) {
                    setShow1(true);
                }
                else {
                    history.push('/login');
                }
            })
                .catch(err => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err)
        }
    }

    /*const increment=()=>{
        setShow2(true);
        dispatch2({type:'INCREMENT'});
    }
    const decrement=()=>{
        dispatch2({type:'DECREMENT'});
    }*/

    //get all the cart information for the logged in user
    const getproduct = async () => {
        try {
            axios.get('https://api.scratchnest.com/products', { withCredentials: true })
                .then((res) => {
                    const prods = res.data.products;
                    setProds(prods);
                }).catch((err) => {
                    console.log(err);
                })
        }
        catch (err) {
            console.log(err);
        };
    }


    const Search_Products = prods.map((item => {
        var count = 0;
        if (item.title.toLowerCase().indexOf(text.trim().toLowerCase()) >= 0 || item.itemType.toLowerCase().indexOf(text.trim().toLowerCase()) >= 0) {
            return (
                <div className="col-sm-6 col-md-4 col-12" data-aos="fade" >
                    <div id="box">
                        <div id="img_div" onClick={() => { history.push(item.path) }}>
                            <img src={item.imageUrl} id="img"  />
                        </div>
                        <div id="heading2">
                            <div className="container-fluid">
                                <div className="row row2">
                                    <div className="col-sm-3 col-md-3 col-3">
                                        <h2>{item.title.replace(/\\n/g, '\n')}</h2>
                                    </div>
                                    <div className="col-sm-1 col-md-1 col-1">
                                        <div id="line"></div>
                                    </div>
                                    <div className="col-sm-7 col-md-7 col-7">
                                        <h3>{item.itemType.replace(/\\n/g, '\n')} </h3>
                                    </div>
                                </div>

                                <div className="row row3">
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <p>{item.description.replace(/\\n/g, '\n')}</p>
                                    </div>
                                    {!item.price == '' ?
                                        <div className="col-sm-12 col-md-12 col-12">
                                            <h4>₹ {item.price}</h4>
                                        </div> : <h4> Coming Soon</h4>}
                                    {!item.price == '' ?
                                        <div className="col-sm-12 col-md-12 col-12">
                                            <button className="cart_button button1" onClick={() => { history.push("/order_now?id=" + item._id) }}>Buy Now</button>
                                        </div> : <div className="col-sm-12 col-md-12 col-12">
                                            <button className="cart_button button1">Coming Soon</button>
                                        </div>}
                                    {!item.price == '' ?
                                        <div className="col-sm-12 col-md-12 col-12">
                                            <button className="cart_button button2" onClick={() => addToCart(item._id)}><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
                                        </div> : <div className="col-sm-12 col-md-12 col-12">
                                            <button className="cart_button button2" > Coming Soon</button>
                                        </div>}
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button3" ><i class="fa fa-star" ></i>  Move to wishlist</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    ));


    const Products = prods.map((item) => {
        if (item.category.replace(/\\n/g, '\n') == selectedprod) {
            return (
                <div className="col-sm-6 col-md-4 col-12" data-aos="fade" >
                    <div id="box">
                        <div id="img_div" onClick={() => { history.push(item.path) }}>
                            <img src={item.imageUrl} id="img"  />
                        </div>
                        <div id="heading2">
                            <div className="container-fluid">
                                <div className="row row2">
                                    <div className="col-sm-3 col-md-3 col-3">
                                        <h2>{item.title.replace(/\\n/g, '\n')}</h2>
                                    </div>
                                    <div className="col-sm-1 col-md-1 col-1">
                                        <div id="line"></div>
                                    </div>
                                    <div className="col-sm-7 col-md-7 col-7">
                                        <h3>{item.itemType.replace(/\\n/g, '\n')} </h3>
                                    </div>
                                </div>

                                <div className="row row3">
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <p>{item.description.replace(/\\n/g, '\n')}</p>
                                    </div>
                                    {!item.price == '' ?
                                        <div className="col-sm-12 col-md-12 col-12">
                                            <h4>₹ {item.price}</h4>
                                        </div> : <h4> Coming Soon</h4>}
                                    {!item.price == '' ?
                                        <div className="col-sm-12 col-md-12 col-12">
                                            <button className="cart_button button1" onClick={() => { history.push("/order_now?id=" + item._id) }}>Buy Now</button>
                                        </div> : <div className="col-sm-12 col-md-12 col-12">
                                            <button className="cart_button button1">Coming Soon</button>
                                        </div>}
                                    {!item.price == '' ?
                                        <div className="col-sm-12 col-md-12 col-12">
                                            <button className="cart_button button2" onClick={() => addToCart(item._id)}><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
                                        </div> : <div className="col-sm-12 col-md-12 col-12">
                                            <button className="cart_button button2" > Coming Soon</button>
                                        </div>}
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button3" ><i class="fa fa-star" ></i>  Move to wishlist</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    });


    const OurProducts = prods.map((item) => (
        item.popular == true && item.category != 'IOT' ?
            <div className="col-sm-6 col-md-4 col-12" data-aos="zoom-out" >
                <div id="box">
                    <div id="img_div">
                        <img src={item.imageUrl} id="img" onClick={() => { history.push(item.path) }} />
                    </div>

                    <div id="heading2">
                        <div className="container-fluid">
                            <div className="row row2">
                                <div className="col-sm-3 col-md-3 col-3">
                                    <h2>{item.title.replace(/\\n/g, '\n')}</h2>
                                </div>
                                <div className="col-sm-1 col-md-1 col-1">
                                    <div id="line"></div>
                                </div>
                                <div className="col-sm-7 col-md-7 col-7">
                                    <h3>{item.itemType.replace(/\\n/g, '\n')} </h3>
                                </div>
                            </div>

                            <div className="row row3">
                                <div className="col-sm-12 col-md-12 col-12">
                                    <p>{item.description.replace(/\\n/g, '\n')}</p>
                                </div>
                                {!item.price == '' ?
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <h4>₹ {item.price}</h4>
                                    </div> : <h4> Coming Soon</h4>}
                                {!item.price == '' ?
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button1" onClick={() => { history.push("/order_now?id=" + item._id) }}>Buy Now</button>
                                    </div> : <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button1">Coming Soon</button>
                                    </div>}
                                {!item.price == '' ?
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button2" onClick={() => addToCart(item._id)}><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
                                    </div> : <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button2" > Coming Soon</button>
                                    </div>}
                                <div className="col-sm-12 col-md-12 col-12">
                                    <button className="cart_button button3" onClick={() => addToWishlist(item._id)}><i class="fa fa-star"></i>Move to wishlist</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null
    ));





    /*const OurProducts = OurItems.map((item) => (

        <div className="col-sm-6 col-md-4 col-12" data-aos="fade" >
            <div id="box">
                <div id="img_div" onClick={() => { history.push(item.path) }}>
                    <img src={item.image} id="img" />
                </div>

                <div id="heading2">
                    <div className="container-fluid">
                        <div className="row row2">
                            <div className="col-sm-3 col-md-3 col-3">
                                <h2>{item.Item_name}</h2>
                            </div>
                            <div className="col-sm-1 col-md-1 col-1">
                                <div id="line"></div>
                            </div>
                            <div className="col-sm-7 col-md-7 col-7">
                                <h3>{item.Item_type} </h3>
                            </div>
                        </div>

                        <div className="row row3">
                            <div className="col-sm-12 col-md-12 col-12">
                                <p>{item.Description}</p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-12">
                                <h4>₹ {item.price}</h4>
                            </div>


                            <div className="col-sm-12 col-md-12 col-12">
                                <button className="cart_button button1" >Buy Now</button>
                            </div>


                            <div className="col-sm-12 col-md-12 col-12">
                                <button className="cart_button button2" onClick={increment}><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
                            </div>

                            <div className="col-sm-12 col-md-12 col-12">
                                <button className="cart_button button3" ><i class="fa fa-star"></i>Move to wishlist</button>
                            </div>



                        </div>

                    </div>





                </div>


            </div>
        </div>

    ));*/







    return (<>

        <Modal show={show2} className="modal"

            aria-labelledby="contained-modal-title-vcenter" style={{ "padding": "3%" }}
            centered
        >
            <div className="container successmodal" style={{ "textAlign": "center", "width": "80%", "margin": "10%" }}>
                <img src="https://res.cloudinary.com/scratchnest/image/upload/v1629638146/success_rnxfot.png" style={{ "width": "20%" }} />
                <h1 className="text-success" style={{ "textAlign": "center" }}> Success</h1>
                <p> Product added to Cart successfully   </p>
                <button className="btn btn-lg btn-success" onClick={handleRedirecting} >Proceed</button>
            </div>



        </Modal>



        <Topheader />
        <div id="store_scr">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 col-md-6 col-12" id="input">
                        <input type="text" placeholder="Search for Products..." onChange={(e) => { handleChange(e.target.value) }} value={text} />
                    </div>

                </div>
            </div>




            <div className="container-fluid" id="div2" style={{ display: search2 ? 'block' : 'none' }}>
                <div className="row">

                    <div id="heading" className="col-sm-12 col-md-12 col-12">
                        <h1> Your Search Results</h1>

                    </div>



                    {/*Product code begins here -------------------------------------------------------------------*/}
                    {Search_Products}
                    {/*Product code end here -------------------------------------------------------------------*/}

                </div>
            </div>


            <div className="container-fluid" id="div2" style={{ display: !search ? 'block' : 'none' }}>
                <div className="row">

                    <div id="heading" className="col-sm-12 col-md-12 col-12">
                        <h1> ScratchNest Store</h1>
                        <p>Hello there! Welcome to ScratchNest Store.</p>
                    </div>



                    {/*Product code begins here -------------------------------------------------------------------
                    {Products}
                    Product code end here -------------------------------------------------------------------*/}

                    <div className="col-sm-6 col-md-6 col-12">
                        <div id="box2" >
                            <div className="row row2">
                                <div className="col-sm-5 col-md-5 col-5 div">
                                    <div id="heading">
                                        <table>
                                            <tr>
                                                <h3>RFID</h3></tr><tr>
                                                <button className="btn btn-primary" onClick={() => { setSelectedProd("RFID"); setSearch(true); setSelect(true); window.scrollTo(0, 0); }}>Buy Now</button> </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-md-7 col-7 div">
                                    <div id="heading2">     <img src={rfid} /></div>

                                </div>


                            </div>

                        </div>
                    </div>

                    <div className="col-sm-6 col-md-6 col-12">
                        <div id="box2">
                            <div className="row row2">
                                <div className="col-sm-5 col-md-5 col-5 div">
                                    <div id="heading">
                                        <table>
                                            <tr>
                                                <h3>Beacon</h3></tr><tr>
                                                <button className="btn btn-primary" onClick={() => { setSelectedProd("Beacon"); setSearch(true); setSelect(true); window.scrollTo(0, 0); }}>Buy Now</button> </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-md-7 col-7 div">
                                    <div id="heading2">     <img src={beacon} /></div>

                                </div>


                            </div>

                        </div>
                    </div>


                    <div className="col-sm-6 col-md-6 col-12">
                        <div id="box2">
                            <div className="row row2">
                                <div className="col-sm-5 col-md-5 col-5 div">
                                    <div id="heading">
                                        <table>
                                            <tr>
                                                <h3>Data Logger</h3></tr><tr>
                                                <button className="btn btn-primary" onClick={() => { setSelectedProd("DataLogger"); setSearch(true); setSelect(true); window.scrollTo(0, 0); }}>Buy Now</button> </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-md-7 col-7 div">
                                    <div id="heading2">     <img src={ambitag} /></div>

                                </div>


                            </div>

                        </div>
                    </div>


                    <div className="col-sm-6 col-md-6 col-12">
                        <div id="box2">
                            <div className="row row2">
                                <div className="col-sm-5 col-md-5 col-5 div">
                                    <div id="heading">
                                        <table>
                                            <tr>
                                                <h3>IOT Products</h3></tr><tr>
                                                <button className="btn btn-primary" onClick={() => { history.push("/store") }}>Buy Now</button> </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-md-7 col-7 div">
                                    <div id="heading2">     <img src={iot} /></div>

                                </div>


                            </div>

                        </div>
                    </div>






                </div>
            </div>







            <div className="container">
                <button className="btn btn-outline-primary" style={{ display: search ? 'block' : 'none' }} onClick={ShowAll} >Show All Products </button>
            </div>

            <div className="container-fluid" id="div2" style={{ display: !search ? 'block' : 'none' }}>
                <div className="row">

                    <div id="heading" className="col-sm-12 col-md-12 col-12">
                        <h1> Popular Products</h1>
                        <p></p>
                    </div>

                    {/*Product code begins here -------------------------------------------------------------------*/}
                    {OurProducts}
                    {/*Product code end here -------------------------------------------------------------------*/}

                </div>
            </div>



            <div className="container-fluid" id="div2" style={{ display: select ? 'block' : 'none' }}>
                <div className="row">

                    <div id="heading" className="col-sm-12 col-md-12 col-12">
                        <h1> Popular Products</h1>
                        <p></p>
                    </div>



                    {/*Product code begins here -------------------------------------------------------------------*/}
                    {Products}
                    {/*Product code end here -------------------------------------------------------------------*/}

                </div>
            </div>







        </div>

        <Footer />
    </>
    );
}


export default Store;