import { useForm } from "react-hook-form";
import react,{useState , useEffect} from 'react';
import { useHistory } from "react-router";

import Topheader  from "../top_header";
import "./Register.scss";
import img1 from './img.png';
import { css } from "@emotion/react";
import BarLoader from "react-spinners/ScaleLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FaRegEyeSlash } from "react-icons/fa";
import { Modal, Button, Form } from "react-bootstrap";
import {connect} from 'react-redux';
import {AddPassCat , AddMessage , Disappear,captchaError} from '../../redux/action/PassAction.js';
//import Config from '../../config.json';
import ReCAPTCHA from "react-google-recaptcha";
import Error from './error.png';
import {Link} from 'react-router-dom';
import success from './success.png';
import {createBrowserHistory} from 'history';
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
const Recaptcha = require('react-recaptcha');

const SITE_KEY = "6LfJOK4bAAAAAMW878jeezl7fkqPmTcZoFzCYWrz";
const eye = <FontAwesomeIcon icon={faEye} />;


function Register(props){
  const [loader,setLoading]=useState(false);
  const [show, setShow] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
const [captcha,setCaptcha]=useState(false);
  const handleShow = () => setShow(true);
const handleErrorShow=()=> setErrorShow(true);
const [passwordShown, setPasswordShown] = useState(false);
const [CpasswordShown, setCPasswordShown] = useState(false);

var history=useHistory();
const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };


  const togglePasswordVisiblity2 = () => {
    setCPasswordShown(CpasswordShown ? false : true);
  };
  



  const onLoginFormSubmit = (e) => {
    e.preventDefault();
  };
  
  

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Forgot password Page";
      }, [])

      const handleClose = () => {
        setShow(false)
        removeRecaptcha()
        //redireted to login page 

      }
/*
      const handleClose = () => {
        setShow(false)
        props.Disappear();
         
      
      };


      const handleErrorClose = () => {
        setErrorShow(false)
        props.Disappear();
         
      
      };

      useEffect(() => {

        if(props.message!=""){
          setLoader(false);
          
          if(props.message=="data saved success"){
            handleShow();
          }
          else{
            handleErrorShow();
          }

        }
      });

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };
  
var l=     <BarLoader  color="#1FD9F3"  size={150} />;
       
      
      const fetchData = ()=>{
             
        if(!captcha){
          props.captchaError();
        }
        else{

        setLoader(true);
        props.addPassCat(username,fullname,contact_no,email,address,city,pincode,password)
        }
     
        
      }
*/
var l=     <BarLoader  color="#1FD9F3"  size={150} />;
      const [username,setUsername]=useState('');
      const [name,setname]=useState('');
      const [contact_no,setContact]=useState('');
      const [email,setEmail]=useState('');
      const[error,setError]=useState('');
      const [password,setPassword]=useState('');
      const [Cpassword,setCPassword]=useState('');
      
      const [address,setAddress]=useState('');
      const [city,setCity]=useState('');
      const [pincode,setPincode]=useState('');
      const [response, setResponse] = useState(null);
const [State,setState]=useState('');



      
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
   

    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const removeRecaptcha = () => {
    const script = document.getElementById("recaptcha-key");
    if (script) {
        script.remove();
    }

    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElems.length) {
        recaptchaElems[0].remove();
    }
}

const handleErrorClose=()=>{
  setError("");
  setErrorShow(false);   

}
 
  const handleOnClick = e => {
    e.preventDefault();

//if( password != Cpassword ){
  //setError("Both Passwords should match")
  //setErrorShow(true);
{/*
else if(username.length<8 || username.length>12 ){
  setError("username size should be between 8 to 12 ");
  setErrorShow(true);

 }
 else if(username.length>=8 && username.length<=12){
     var intcount=0;

     for(var i=0;i<username.length;i++){
if(username.charAt(i)<97){
  intcount++;
}
     }
     if(intcount<3){
      setError("username should consist three numbers atleast");
      setErrorShow(true);
     }
 
  }
  else if(password.length<8){
    setError("password should be of minimum size of 8");
    setErrorShow(true);
  } */} 
//}
  //else{
 
    setLoading(true); 
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        submitData(token);
     
      });
    });

//setShow(true);
  
 // }
}


//here is the function to redirect the user to last page -------------------------------------------------
 const handleRedirecting=()=>{

  setShow(false);
     //redireted to login page 
     //history.push("/new_login");
     props.history.goBack()


 }

 //-----------------------------------------------------------------------------------------------------------
  const submitData = token => {
    console.log(token);
    // call a backend API to verify reCAPTCHA response
    fetch('https://api.scratchnest.com/forgotPassword', {
      method: 'PUT',
      credentials:'include',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "email": email,
      "g-recaptcha-response":token
      })
      
    }).then(res => res.json()).then(res => {
      setLoading(false);
      setResponse(res);

      //if user registered successfully
      if(res.status==true){
         setEmail('');
        setShow(true);
      }
      else{
        //else if server showed some errro in registration
        setError(res.error);
        setErrorShow(true);


      }
      
    });
  }
 
  

return(<>

    <div id="forgot">

    <Modal show={show} className="modal"   onHide={() => setShow(false)}
 
      aria-labelledby="contained-modal-title-vcenter" style={{"padding":"3%"}}
      centered
    >
   <div className="container successmodal" style={{"textAlign":"center","width":"80%","margin":"10%"}}>
     <img src={success} style={{"width":"20%"}} />
         <h1 className="text-success" style={{"textAlign":"center"}}> Success</h1> 
         <p> Password Reset Link Sent </p>
         <button className="btn btn-lg btn-success" onClick={handleClose} >Proceed</button>
   </div>

        
  
    </Modal>

    <Modal show={errorshow} className="modal"   onHide={() => setErrorShow(false)}
  
      aria-labelledby="contained-modal-title-vcenter" style={{"padding":"3%"}}
      centered
    >
   <div className="container successmodal" style={{"textAlign":"center","width":"80%","margin":"10%"}}>
     <img src={Error} style={{"width":"20%"}} />
         <h1 className="text-danger" style={{"textAlign":"center"}}> Error</h1> 
         <p> {error}  </p>
         <button className="btn btn-lg btn-danger" onClick={handleErrorClose} >Close</button>
   </div>

        
  
    </Modal>

   

        <div id="form" className="container">
            <div id="heading">
              {loader && l}
        
            <h1> Reset Password</h1>
            <p>Please provide your registered email id below. We will send you the password rest link to your registered email id.</p>
          
          
            </div>
            <div className="row">
  <div className="col-sm-12 col-md-12 col-12">
     
  <form onSubmit={handleOnClick}>
  <div className="form-group">
    <input type="email" className="form-control" placeholder="Email"  value={email}  onChange={ e=>(setEmail(e.target.value))}/>
  </div>
  <div className="form-group">
  
 <button type="submit"  className="btn btn-outline-primary" disabled={loader}   > Reset </button>
  </div>
  
 

</form>
{/*disabled={loader} */ } 


      </div> 
      <div className="col-sm-7 col-md-7 col-12">
     
     </div> 
                      
                       
            </div>
            
             </div>
    </div>
    </>
)


}




  
  export default (Register);