export var initial_number = parseInt(localStorage.getItem("numberOfItems"));


export const counterReducer = (state, action = {}) => {
  const { type } = action
  switch (type) {
    case 'INCREMENT': {

      return state = parseInt(localStorage.getItem("numberOfItems"))
      //return num;
    }
    case 'DECREMENT': {
      return state = parseInt(localStorage.getItem("numberOfItems"))
    }
    case 'SET': {

      return parseInt(localStorage.getItem("numberOfItems"));
    }
    default:
      return 
  }
}

