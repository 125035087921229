import "./App.css";
import {createContext , useReducer} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,Redirect,
  useHistory,
} from "react-router-dom";
import { initialState, reducer , items_number } from './reducer/userReducer';
import { counterReducer ,initial_number} from './reducer/Counter.js';

import HomeSector from "./components/HomeSectors/HomeSector.tsx";

import React, { useEffect, useState } from "react";
import Topheader from "./components/top_header";

import Splashscreen from "./components/Splash Screen/Splashscreen";

import DataLoggerSec from "./components/Sector/Logger/DataLogger_sec";
import EducationSec from "./components/Sector/Education/Education";

import BeaconSec from "./components/Sector/Beacon/Beacon";

import RfidSec from './components/Sector/RFID/Rfid';
import Cart from './components/Cart/temp';
import Temp2 from './components/Cart/temp';
//import Guest from './components/GuestCheckout/Guest';
import Dataloggern from "./components/Products/Dataloggern/Dataloggern";
import Shop from './components/shop/demostore';
import Twoport_Rfid from "./components/Products/Rfid/twoport/Rfidproduct2";
import OnePort_Rfid from "./components/Products/Rfid/oneport/Rfidproduct2";
import Beacon_product from "./components/Products/Beacon/TrackingBeacon/TrackingBeacon/Rfidproduct2";
import CircularAntenna from './components/Products/Antenna/CircularAntenna/Rfidproduct2';
import LinearAntenna from './components/Products/Antenna/LinearAntenna/Rfidproduct2';

import FixedReader_Two from "./components/Products/Rfid/fixedReader2/Rfidproduct2";
import Error from './components/Error/error';
import Register from './components/Registration/Register.js';
import TempRegister from './components/Registration/code';
import Profile from './components/Profile/profile';
import Wishlist from './components/wishlist/profile';
import EditProfile from './components/Edit_Profile/Register.js';
import ForgotPassword from "./components/forgot_password/Register";
import ResetPassword from './components/reset_password/Register';
import FixedReader_Four from "./components/Products/Rfid/fixedReader4/Rfidproduct2";

import Login from "./components/Login/Login";
import NewLogin from "./components/NewUserLogin/Login";

 import {Provider} from 'react-redux';
 import Topheader2 from "./components/top_header/index2";
import OrderNow from './components/OrderNow/temp';

import store from './redux/store';
import Getdetails from './components/getDetails/GetDetails';
import Temp from './components/temp/temp'
import Confirmation from './components/orderConfirmation/confirmation';
import About from './components/About/About2';
import Contact from './components/Contact/Contact.js';
import Support from './components/Support/Support';
import Order_failure from './components/order_failure/confirmation';
import GetDetails from "./components/getDetails/GetDetails";
import Store from './components/Store/Store';

import Iotlab_Product from './components/iot_product/Product';
import CC3220 from './components/iot_product/products/CC3220';
import STM32L412KB from './components/iot_product/products/STM32L412KB';
import W25Q16JV from './components/iot_product/products/W25Q16JV';
import LIS3DH from './components/iot_product/products/LIS3DH';
import SHT40AD1BR3 from './components/iot_product/products/SHT40-AD1B-R3';
import STTS751 from './components/iot_product/products/STTS751';
import HTS221 from './components/iot_product/products/HTS221';
import BME680 from './components/iot_product/products/BME680';
import Grove_Shield from './components/iot_product/products/Grove_Shield';
import RL_Z12D_U from './components/iot_product/products/RL-Z12D-U';
import CNG_Detector from './components/iot_product/products/CNG_Detector';
import VL53L3CX from './components/iot_product/products/VL53L3CX';
import Buzzer from './components/iot_product/products/Buzzer';

import Blog from './components/Blog/Blog';
import Blog1 from './components/Blog/datalogger/blog1';//16 Reasons to Have Data Loggers for Temperature Logging
 import Blog2 from './components/Blog/datalogger/blog2';  //AmbiTag Temperature Data Loggers: Features And Usages
import Blog3 from './components/Blog/datalogger/blog3';//The Applications of AmbiTag Data Loggers: A Detailed Analysis
import Blog4 from './components/Blog/datalogger/blog4';//Applications of RFID: How It Works And The Usages
import Blog5 from './components/Blog/datalogger/blog5';//Top 5 Devices That Use NFC: Its Features And Usages

export const UserContext = createContext();


function App() {
  
  const [loading, setLoading] = useState(true);
  const reload = () => window.location.reload();
  useEffect(() => {
    setTimeout(() => setLoading(false), 4000);
  }, []);



  
/*  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // the user is logged in
        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        //user is logged out
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    });
    console.log("USER IS>>", user);

    return () => {
      //cleanup once logout
      unsubscribe();
    };
  }, []);

*/

const [state,dispatch] = useReducer(reducer,initialState);
const [state2,dispatch2] = useReducer(counterReducer,initial_number);


  return (
    <>
    <UserContext.Provider value={{state,dispatch,state2,dispatch2}}>
       <Provider store={store}>
      <div className="App" >
        {loading == true ? (
          <Splashscreen />
        ) : (
          <Router>
        
            <Switch>
              <Route component={HomeSector} exact path="/" />
              <Route component={DataLoggerSec} exact path="/sector/datalogger" />
              <Route component={RfidSec} path="/sector/RFID" exact/>
              <Route component={Register} path="/register" exact/>
              <Route component={Login} path="/login" exact/>
              <Route component={NewLogin} path="/new_login" exact/>
              <Route component={About} path="/about" exact/>
              <Route component={Contact} path="/contact" exact/>
              <Route component={Support} path="/support" exact/>
              <Route component={Profile} path="/profile" exact/>
              <Route component={Wishlist} path="/wishlist" exact/>
              <Route component={EditProfile} path="/editprofile" exact/>
              <Route component={ForgotPassword} path="/forgotpassword" exact/>
              <Route component={ResetPassword} path="/resetpassword" exact/>
              <Route component={GetDetails} path="/get_details" exact/>
              <Route component={Store} path="/store" exact/>
              {/*<Route component={Guest} path="/guest" exact/>*/}
              <Route component={CC3220} path="/CC3220" exact/>
              <Route component={STM32L412KB} path="/STM32L412KB" exact/>

                
                    <Route component={Blog} path="/blog" exact/>
              <Route component={Blog1} path="/blog/16_Reasons_to_Have_Data_Loggers_for_Temperature_Logging" exact/>
              <Route component={Blog2} path="/blog/AmbiTag_Temperature_Data_Loggers_Features_And_Usages" exact/>
              <Route component={Blog3} path="/blog/The_Applications_of_AmbiTag_Data_Loggers_A_Detailed_Analysis" exact/>
              <Route component={Blog4} path="/blog/Applications_of_RFID_How_It_Works_And_The_Usages" exact/>
              <Route component={Blog5} path="/blog/Top5_Devices_That_Use_NFC_Its_Features_And_Usages" exact/>
                
              <Route component={W25Q16JV} path="/W25Q16JV" exact/>
              <Route component={LIS3DH} path="/LIS3DH" exact/>
              <Route component={SHT40AD1BR3} path="/SHT40-AD1B-R3" exact/>
              <Route component={STTS751} path="/STTS751" exact/>
              <Route component={HTS221} path="/HTS221" exact/>
              <Route component={BME680} path="/BME680" exact/>
              <Route component={Grove_Shield} path="/Grove_Shield" exact/>
              <Route component={RL_Z12D_U} path="/RL-Z12D-U" exact/>
              <Route component={CNG_Detector} path="/CNG_Detector" exact/>
              <Route component={VL53L3CX} path="/VL53L3CX" exact/>
              <Route component={Buzzer} path="/Buzzer" exact/>
              



              <Route component={Iotlab_Product} path="/Product_iotlab" exact/>
            
              <Route component={Confirmation} path="/order_confirmation" exact/>
              <Route component={Order_failure} path="/order_failure" exact/>
              
              <Route component={TempRegister} path="/register2" exact/>
              <Route component={Shop} path="/shop" exact/>
              
              <Route component={EducationSec} path="/sector/Education" exact/>
              
              <Route component={Cart} path="/cart" exact/>
              <Route component={Temp2} path="/temp2" exact/>
              
              <Route component={Temp} path="/temp" exact/>
              <Route component={OrderNow} path="/order_now" exact/>
              
              <Route component={Dataloggern} path="/products/logger" exact/>
              <Route component={OnePort_Rfid} path="/products/Rfid/oneport" exact/>
              <Route component={Twoport_Rfid} path="/products/Rfid/twoport" exact/>
              <Route component={FixedReader_Two} path="/products/Rfid/fixedReader_Twoport" exact />
              <Route component={FixedReader_Four} path="/products/Rfid/fixedReader_Fourport" exact/>
              <Route component={Beacon_product} path="/products/Beacon" exact/>
              <Route component={LinearAntenna} path="/products/LinearAntenna" exact/>
              
              <Route component={CircularAntenna} path="/products/CircularAntenna" exact/>
              
              <Route component={BeaconSec} path="/sector/Beacon" exact/>
              <Route component={Error} path="/error"/>
              <Redirect to="/error"/>
            </Switch>
          </Router>
        )}
      </div>
      </Provider>
      </UserContext.Provider>
    </>
  );
}

export default App;
