import { useForm } from "react-hook-form";
import react, { useState, useEffect } from 'react';
import { useHistory } from "react-router";

import Topheader from "../top_header";
import "./Register.scss";
import img1 from './img.png';
import { css } from "@emotion/react";
import BarLoader from "react-spinners/ScaleLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FaRegEyeSlash } from "react-icons/fa";
import { Modal, Button, Form } from "react-bootstrap";
import { connect } from 'react-redux';
import { AddPassCat, AddMessage, Disappear, captchaError } from '../../redux/action/PassAction.js';
import Config from '../../config.json';
import google from './google.png';
import twitter from './twitter.png';
import facebook from './facebook.png';
import ReCAPTCHA from "react-google-recaptcha";
import Error from './error.png';
import { Link } from 'react-router-dom';
import success from './success.png';
import { createBrowserHistory } from 'history';
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
//const Recaptcha = require('react-recaptcha');
import axios from 'axios';

const SITE_KEY = "6LfJOK4bAAAAAMW878jeezl7fkqPmTcZoFzCYWrz";
const eye = <FontAwesomeIcon icon={faEye} />;


function Register(props) {
  const [loader, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [user, setUser] = useState([]);
  const [add, setAdd] = useState([]);

  const [errorshow, setErrorShow] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const handleShow = () => setShow(true);
  const handleErrorShow = () => setErrorShow(true);
  const [passwordShown, setPasswordShown] = useState(false);
  var history = useHistory();
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const onLoginFormSubmit = (e) => {
    e.preventDefault();
  };



  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Edit Profile";
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    }

    // load the script by passing the URL


    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
    getUser()
  }, [])

  const handleClose = () => {
    setShow(false)
    //redireted to login page 
    props.history.goBack()

  }
  /*
        const handleClose = () => {
          setShow(false)
          props.Disappear();
           
        
        };
  
  
        const handleErrorClose = () => {
          setErrorShow(false)
          props.Disappear();
           
        
        };
  
        useEffect(() => {
  
          if(props.message!=""){
            setLoader(false);
            
            if(props.message=="data saved success"){
              handleShow();
            }
            else{
              handleErrorShow();
            }
  
          }
        });
  
      const [passwordShown, setPasswordShown] = useState(false);
      const togglePasswordVisiblity = () => {
          setPasswordShown(passwordShown ? false : true);
        };
    
  var l=     <BarLoader  color="#1FD9F3"  size={150} />;
         
        
        const fetchData = ()=>{
               
          if(!captcha){
            props.captchaError();
          }
          else{
  
          setLoader(true);
          props.addPassCat(username,fullname,contact_no,email,address,city,pincode,password)
          }
       
          
        }
  */
  const getUser = async () => {
    try {
      axios.get('https://api.scratchnest.com/orders', { withCredentials: true })
        .then((res) => {
          console.log(res.data.user.address[0])
          const user = res.data.user;
          const address = res.data.user.address[0];
          setUser(user)
          setAdd(address)
          setUsername(res.data.user.username);
          setEmail(res.data.user.email);
          setname(res.data.user.name);
          setContact(res.data.user.contact_no);
          setAddress(res.data.user.address[0].street_add);
          setState(res.data.user.address[0].state);
          setCity(res.data.user.address[0].city);
          setPincode(res.data.user.address[0].pin);
          setState('');
          if (user) {
            setLoading(false)
          }
        }).catch((err) => {
          console.log(err);
          props.history.push('/login');
        })
    }
    catch (err) {
      console.log(err);
      props.history.push('/login');
    };
  }

  var l = <BarLoader color="#1FD9F3" size={150} />;
  const [username, setUsername] = useState('');
  const [name, setname] = useState('');
  const [contact_no, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [response, setResponse] = useState(null);
  const [State, setState] = useState('');



  const handleErrorClose = () => {
    setError("");
    setErrorShow(false);

  }

  const handleOnClick = e => {
    e.preventDefault();

    if (!name || !email || !username || !contact_no || !pincode || !address) {
      setError("please fill the required deatils")
      setErrorShow(true);
      {/*
else if(username.length<8 || username.length>12 ){
  setError("username size should be between 8 to 12 ");
  setErrorShow(true);

 }
 else if(username.length>=8 && username.length<=12){
     var intcount=0;

     for(var i=0;i<username.length;i++){
if(username.charAt(i)<97){
  intcount++;
}
     }
     if(intcount<3){
      setError("username should consist three numbers atleast");
      setErrorShow(true);
     }
 
  }
  else if(password.length<8){
    setError("password should be of minimum size of 8");
    setErrorShow(true);
  } */}
    }
    else {

      setLoading(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
          submitData(token);

        });
      });


    }
  }


  //here is the function to redirect the user to last page -------------------------------------------------
  const handleRedirecting = () => {

    setShow(false);
    //redireted to login page 
    //history.push("/new_login");
    props.history.goBack()


  }

  //-----------------------------------------------------------------------------------------------------------
  const submitData = token => {
    console.log(token);
    // call a backend API to verify reCAPTCHA response

    fetch(Config.EditProfile, {
      method: 'POST',
      withCredentials: true,
      headers: {
        "Content-Type": "application/json"
      },
      credentials: 'include',
      body: JSON.stringify({
        "id": user._id,
        "username": username,
        "email": email,
        "name": name,
        "contact_no": contact_no,
        "address": { "street_add": address, "city": city, "pin": pincode, "state": State },
        "profile": "Naan",
        "g-recaptcha-response": token
      })

    }).then(res => res.json()).then(res => {
      setLoading(false);
      setResponse(res);

      //if user registered successfully
      if (res.status == true) {
        setUsername('');
        setEmail('');
        setname('');
        setContact('');
        setAddress('');
        setCity('');
        setPincode('');
        setState('');
        setShow(true);
      }
      else {
        //else if server showed some errro in registration
        setError(res.error);
        setErrorShow(true);


      }

    });
  }



  return (<>

    <div id="register">
      <Topheader />

      <Modal show={show} className="modal" onHide={() => setShow(false)}

        aria-labelledby="contained-modal-title-vcenter" style={{ "padding": "3%" }}
        centered
      >
        <div className="container successmodal" style={{ "textAlign": "center", "width": "80%", "margin": "10%" }}>
          <img src={success} style={{ "width": "20%" }} />
          <h1 className="text-success" style={{ "textAlign": "center" }}> Success</h1>
          <p> Profile Updated   </p>
          <button className="btn btn-lg btn-success" onClick={handleClose} >Proceed</button>
        </div>



      </Modal>

      <Modal show={errorshow} className="modal" onHide={() => setErrorShow(false)}

        aria-labelledby="contained-modal-title-vcenter" style={{ "padding": "3%" }}
        centered
      >
        <div className="container successmodal" style={{ "textAlign": "center", "width": "80%", "margin": "10%" }}>
          <img src={Error} style={{ "width": "20%" }} />
          <h1 className="text-danger" style={{ "textAlign": "center" }}> Error</h1>
          <p> {error}  </p>
          <button className="btn btn-lg btn-danger" onClick={handleErrorClose} >Close</button>
        </div>



      </Modal>



      <div id="form" className="container-fluid">
        <div id="heading">
          {loader && l}

          <h1> Update Your Profile</h1>
          <br></br>

        </div>
        <div className="row">
          <div className="col-sm-5 col-md-5 col-12">

            <form onSubmit={handleOnClick}>
              <div className="form-group">
              {user.username=='-'?<input type="text" className="form-control" required placeholder="Username" defaultValue='' onChange={e => (setUsername(e.target.value))} required />:
                <input type="text" className="form-control" required placeholder="Username" defaultValue={user.username} onChange={e => (setUsername(e.target.value))} required />}
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Full Name" defaultValue={user.name} onChange={e => (setname(e.target.value))} required />
              </div>
              <div className="form-group">
              {user.contact_no=='-'?<input type="tel" className="form-control" placeholder="Contact No." defaultValue='' onChange={e => (setContact(e.target.value))} required />:
                <input type="tel" className="form-control" placeholder="Contact No." defaultValue={user.contact_no} onChange={e => (setContact(e.target.value))} required />}
              </div>
              <div className="form-group">
                <input type="email" className="form-control" placeholder="Email" defaultValue={user.email} onChange={e => (setEmail(e.target.value))} required />
              </div>

              {/*
  <div className="pass-wrapper form-group">
        <input
        className="form-control"
          placeholder="Password"
          name="password" 
          type={passwordShown ? "text" : "password"}
          value={password}  onChange={ e=>(setPassword(e.target.value))}
        />
        <i onClick={togglePasswordVisiblity}> {eye} </i>
        

      </div>
  */}
              <div className="form-group">
                {add.street_add=='-'?<input type="text" required className="form-control" placeholder="Address" defaultValue='' onChange={e => (setAddress(e.target.value))} />:
                <input type="text" required className="form-control" placeholder="Address" defaultValue={add.street_add} onChange={e => (setAddress(e.target.value))} />}
              </div>

              <div className="form-group">
                {add.state=='-'?<input type="text" required className="form-control" placeholder="State" defaultValue='' onChange={e => (setState(e.target.value))} />:
                <input type="text" required className="form-control" placeholder="State" defaultValue={add.state} onChange={e => (setState(e.target.value))} />}
              </div>

              <div className="form-row">
                <div className="col">
                  {add.city=='-'?<input type="text" required className="form-control" placeholder="City" name="email" defaultValue='' onChange={e => (setCity(e.target.value))} />:
                  <input type="text" required className="form-control" placeholder="City" name="email" defaultValue={add.city} onChange={e => (setCity(e.target.value))} />}
                </div>
                <div className="col">
                  <input type="number" className="form-control" placeholder="Pin-Code" name="pswd" defaultValue={add.pin} onChange={e => (setPincode(e.target.value))} />
                </div>
              </div>

              <div className="form-group" style={{ "padding-top": "5%" }}>
                {/*   not used v2 captcha 
    <ReCAPTCHA 
      sitekey="6Lfnv_AbAAAAAOq8DUN79aJhPC5fP_aSdQUGQF3D"    render="explicit"
    onChange={verifyCallback} 
      onLoad={callback}
/>*/}
              </div>
              <div className="form-group">

                <button type="submit" className="btn btn-outline-primary" disabled={loader}   > Update Profile </button>
              </div>




            </form>
            {/*disabled={loader} */}


          </div>
          <div className="col-sm-7 col-md-7 col-12">
            <img src={img1} id="temp_img" />
          </div>


        </div>

      </div>
    </div>
  </>
  )


}





export default (Register);