import React from 'react';
import Topheader from "../top_header";
import getCart from "../top_header/LoggedOut";
import Footer from '../Footer/footer2';
import './store.scss';
//import Items from './items';
//import OurItems from './Ouritems';
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
//import img2 from './image/img2.png';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Form } from "react-bootstrap";

//import { findByDisplayValue } from "@testing-library/dom";

function Store() {

    const { state, dispatch, state2, dispatch2 } = useContext(UserContext);
    var history = useHistory();

    useEffect(() => {
        const script = document.getElementById("recaptcha-key");
        if (script) {
            script.remove();
        }
        const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
        if (recaptchaElems.length) {
            recaptchaElems[0].remove();
        }
        window.scrollTo(0, 0);
        document.title = "Store";
        getproduct();
        removeRecaptcha();
    }, [])

    const removeRecaptcha = () => {
        const script = document.getElementById("recaptcha-key");
        if (script) {
            script.remove();
        }

        const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
        if (recaptchaElems.length) {
            recaptchaElems[0].remove();
        }
    }

    const [show2, setShow2] = useState(false);
    const [show1, setShow1] = useState(false);
    function handleRedirecting() {
        setShow2(false);
        setShow1(false);
    }

    var [search, setSearch] = useState(false);
    var [text, setText] = useState('');
    var [found, setFound] = useState(false);
    var [prods, setProds] = useState([]);
    const [products, setProducts] = useState(JSON.parse(localStorage.getItem('products')) || []);
    var [totalPrice, setTotalPrice] = useState(JSON.parse(localStorage.getItem('totalPrice')) || 0);
    const ShowAll = () => {

        setSearch(false);
        setText('');

    }
    const handleChange = (x) => {
        setSearch(true);

        setText(x);

        setFound(false);
    }

    //var products = [];
    //var totalPrice = 0  
    async function addToCart(id) {
        try {
            console.log(id);
            var prod = prods.findIndex(objInItems => new String(objInItems._id).trim() == new String(id).trim());
            console.log(id);
            console.log(products);
            console.log(totalPrice)
            fetch('https://api.scratchnest.com/addToCart', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({
                    "id": id
                })

            }).then(res => res.json()).then(res => {
                if (!res.status == true) {
                    const isExisting = products.findIndex(objInItems => new String(objInItems._id).trim() == new String(id).trim());
                    if (isExisting >= 0) {
                        products[isExisting].qty += 1
                        totalPrice = totalPrice + products[isExisting].price
                        setTotalPrice(totalPrice)
                        localStorage.setItem('totalPrice', JSON.stringify(totalPrice));
                    } else {
                        prods[prod].qty = 1
                        prods[prod]['productId'] = prods[prod]['_id']; // Assign new key
                        products.push(prods[prod]);
                        console.log(totalPrice);
                        totalPrice = totalPrice + prods[prod].price
                        setTotalPrice(totalPrice)
                        localStorage.setItem('totalPrice', JSON.stringify(totalPrice));
                    }
                    localStorage.setItem('products', JSON.stringify(products));
                }else{
                    var length = res.user.cart.items.length
                    localStorage.setItem("numberOfItems", length);
                    dispatch2({type:'INCREMENT'});
                }
                setShow2(true);
            })
                .catch(err => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err)
        }
    }

    async function addToWishlist(id) {
        try {
            fetch('https://api.scratchnest.com/addToWishlist', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({
                    "id": id
                })

            }).then(res => res.json()).then(res => {
                console.log(res)
                if (res.status == true) {
                    setShow1(true);
                }
                else {
                    history.push('/login');
                }
            })
                .catch(err => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err)
        }
    }

    //async function increment() {
    //setShow2(true);
    //dispatch2({ type: 'INCREMENT' });
    // }

    // const decrement = async () => {
    //dispatch2({ type: 'DECREMENT' });
    // }


    //get all the cart information for the logged in user
    const getproduct = async () => {
        try {
            axios.get('https://api.scratchnest.com/products', { withCredentials: true })
                .then((res) => {
                    const prods = res.data.products;
                    setProds(prods);
                }).catch((err) => {
                    console.log(err);
                })
        }
        catch (err) {
            console.log(err);
        };
    }

    async function decreaseInCart(id) {
        let products = [];
        let totalPrice = 0
        try {
            //var prod= prods.findIndex(objInItems => new String(objInItems._id).trim() == new String(id).trim());
            fetch('https://api.scratchnest.com/do-decreaseItem', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({
                    "id": id
                })

            }).then(res => res.json()).then(res => {
                if (!res.status == true) {
                    console.log(id);
                    console.log("runnning lgout");
                    const isExisting = products.findIndex(objInItems => new String(objInItems._id).trim() == new String(id).trim());
                    if (isExisting >= 0 && products[isExisting].qty > 1) {
                        products[isExisting].qty -= 1
                    } else {
                        products.splice(isExisting, 1);
                    }
                    localStorage.setItem('products', JSON.stringify(products));
                    getCart()
                }
            })
                .catch(err => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err)
        }
    }

    const Search_Products = prods.map((item => {
        var count = 0;
        if (item.title.toLowerCase().indexOf(text.trim().toLowerCase()) >= 0 || item.itemType.toLowerCase().indexOf(text.trim().toLowerCase()) >= 0) {
            return (
                item.category == 'IOT' ?
                    <div className="col-sm-6 col-md-4 col-12" data-aos="zoom-out" >

                        <div id="box">
                            <div id="img_div">
                                <img src={item.imageUrl} id="img" onClick={() => { history.push(item.path) }} />
                            </div>

                            <div id="heading2">
                                <div className="container-fluid">
                                    <div className="row row2">
                                        <div className="col-sm-3 col-md-3 col-3">
                                            <h2>{item.title.replace(/\\n/g, '\n')}</h2>
                                        </div>
                                        <div className="col-sm-1 col-md-1 col-1">
                                            <div id="line"></div>
                                        </div>
                                        <div className="col-sm-7 col-md-7 col-7">
                                            <h3>{item.itemType.replace(/\\n/g, '\n')} </h3>
                                        </div>
                                    </div>

                                    <div className="row row3">
                                        <div className="col-sm-12 col-md-12 col-12">
                                            <p>{item.description.replace(/\\n/g, '\n')}</p>
                                        </div>
                                    
                                        {!item.price == '' ?
                                            <div className="col-sm-12 col-md-12 col-12">
                                                <h4>₹ {item.price}</h4>
                                            </div> : <h4> Coming Soon</h4>}
                                        {!item.price == '' ?
                                            <div className="col-sm-12 col-md-12 col-12">
                                                <button className="cart_button button1" onClick={() => { history.push("/order_now?id=" + item._id) }}>Buy Now</button>
                                            </div> : <div className="col-sm-12 col-md-12 col-12">
                                                <button className="cart_button button1">Coming Soon</button>
                                            </div>}
                                        {!item.price == '' ?
                                            <div className="col-sm-12 col-md-12 col-12">
                                                <button className="cart_button button2" onClick={() => addToCart(item._id)}><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
                                            </div> : <div className="col-sm-12 col-md-12 col-12">
                                                <button className="cart_button button2" > Coming Soon</button>
                                            </div>}

                                        <div className="col-sm-12 col-md-12 col-12">
                                            <button className="cart_button button3" onClick={() => addToWishlist(item._id)}><i class="fa fa-star" ></i>  Move to wishlist</button>
                                        </div>



                                    </div>

                                </div>





                            </div>


                        </div>
                    </div> : null
            )
        }






    }



    ));



    //Items

    const Products = prods.map((item) => (
        item.popular == true && item.category == 'IOT' ?
            <div className="col-sm-6 col-md-4 col-12" data-aos="fade" >
                <div id="box">
                    <div id="img_div">
                        <img src={item.imageUrl} id="img" onClick={() => { history.push(item.path) }} />
                    </div>

                    <div id="heading2">
                        <div className="container-fluid">
                            <div className="row row2">
                                <div className="col-sm-3 col-md-3 col-3">
                                    <h2>{item.title.replace(/\\n/g, '\n')}</h2>
                                </div>
                                <div className="col-sm-1 col-md-1 col-1">
                                    <div id="line"></div>
                                </div>
                                <div className="col-sm-7 col-md-7 col-7">
                                    <h3>{item.itemType.replace(/\\n/g, '\n')} </h3>
                                </div>
                            </div>

                            <div className="row row3">
                                <div className="col-sm-12 col-md-12 col-12">
                                    <p>{item.description.replace(/\\n/g, '\n')}</p>
                                </div>
                                {!item.price == '' ?
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <h4>₹ {item.price}</h4>
                                    </div> : <h4> Coming Soon</h4>}
                                {!item.price == '' ?
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button1" onClick={() => { history.push("/order_now?id=" + item._id) }}>Buy Now</button>
                                    </div> : <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button1">Coming Soon</button>
                                    </div>}
                                {!item.price == '' ?
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button2" onClick={() => addToCart(item._id)}><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
                                    </div> : <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button2" > Coming Soon</button>
                                    </div>}

                                <div className="col-sm-12 col-md-12 col-12">
                                    <button className="cart_button button3" onClick={() => addToWishlist(item._id)}><i class="fa fa-star"></i>Move to wishlist</button>
                                </div>



                            </div>

                        </div>





                    </div>


                </div>
            </div> : null

    ));




    const OurProducts = prods.map((item) => (
        item.category == 'IOT' ?
            <div className="col-sm-6 col-md-4 col-12" data-aos="fade" >

                <div id="box">
                    <div id="img_div">
                        <img src={item.imageUrl} id="img" onClick={() => { history.push(item.path) }} />
                    </div>

                    <div id="heading2">
                        <div className="container-fluid">
                            <div className="row row2">
                                <div className="col-sm-3 col-md-3 col-3">
                                    <h2>{item.title.replace(/\\n/g, '\n')}</h2>
                                </div>
                                <div className="col-sm-1 col-md-1 col-1">
                                    <div id="line"></div>
                                </div>
                                <div className="col-sm-7 col-md-7 col-7">
                                    <h3>{item.itemType.replace(/\\n/g, '\n')} </h3>
                                </div>
                            </div>

                            <div className="row row3">
                                <div className="col-sm-12 col-md-12 col-12">
                                    <p>{item.description.replace(/\\n/g, '\n')}</p>
                                </div>
                                {!item.price == '' ?
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <h4>₹ {item.price}</h4>
                                    </div> : <h4> Coming Soon</h4>}
                                {!item.price == '' ?
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button1" onClick={() => { history.push("/order_now?id=" + item._id) }}>Buy Now</button>
                                    </div> : <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button1">Coming Soon</button>
                                    </div>}
                                {!item.price == '' ?
                                    <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button2" onClick={() => addToCart(item._id)}><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
                                    </div> : <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button2" > Coming Soon</button>
                                    </div>}

                                <div className="col-sm-12 col-md-12 col-12">
                                    <button className="cart_button button3" onClick={() => addToWishlist(item._id)}><i class="fa fa-star"></i>Move to wishlist</button>
                                </div>




                            </div>

                        </div>





                    </div>


                </div>
            </div> : null

    ));







    return (<>
        <Modal show={show2} className="modal"

            aria-labelledby="contained-modal-title-vcenter" style={{ "padding": "3%" }}
            centered
        >
            <div className="container successmodal" style={{ "textAlign": "center", "width": "80%", "margin": "10%" }}>
                <img src="https://res.cloudinary.com/scratchnest/image/upload/v1629638146/success_rnxfot.png" style={{ "width": "20%" }} />
                <h1 className="text-success" style={{ "textAlign": "center" }}> Success</h1>
                <p> Product added to Cart successfully   </p>
                <button className="btn btn-lg btn-success" onClick={handleRedirecting} >Proceed</button>
            </div>
        </Modal>
        <Modal show={show1} className="modal"

            aria-labelledby="contained-modal-title-vcenter" style={{ "padding": "3%" }}
            centered
        >
            <div className="container successmodal" style={{ "textAlign": "center", "width": "80%", "margin": "10%" }}>
                <img src="https://res.cloudinary.com/scratchnest/image/upload/v1629638146/success_rnxfot.png" style={{ "width": "20%" }} />
                {/*<h1 className="text-success" style={{ "textAlign": "center" }}> Success</h1>*/}
                <p> Product added to Wishlist successfully   </p>
                <button className="btn btn-lg btn-warning" onClick={handleRedirecting} >Proceed</button>
            </div>
        </Modal>
        <Topheader />
        <div id="store">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 col-md-6 col-12" id="input">
                        <input type="text" placeholder="Search for Products..." onChange={(e) => { handleChange(e.target.value) }} value={text} />
                    </div>

                </div>
            </div>




            <div className="container-fluid" id="div2" style={{ display: search ? 'block' : 'none' }}>
                <div className="row">

                    <div id="heading" className="col-sm-12 col-md-12 col-12">
                        <h1> Your Search Results</h1>

                    </div>



                    {/*Product code begins here -------------------------------------------------------------------*/}
                    {Search_Products}
                    {/*Product code end here -------------------------------------------------------------------*/}

                </div>
            </div>

            <div className="container">
                <button className="btn btn-outline-primary" style={{ display: search ? 'block' : 'none' }} onClick={ShowAll} >Show All Products </button>
            </div>

            <div className="container-fluid" id="div2" style={{ display: !search ? 'block' : 'none' }}>
                <div className="row">

                    <div id="heading" className="col-sm-12 col-md-12 col-12">
                        <h1> Popular Products</h1>

                    </div>



                    {/*Product code begins here -------------------------------------------------------------------*/}
                    {Products}
                    {/*Product code end here -------------------------------------------------------------------*/}

                </div>
            </div>




            <div className="container-fluid" id="div2" style={{ display: !search ? 'block' : 'none' }}>
                <div className="row">

                    <div id="heading" className="col-sm-12 col-md-12 col-12">
                        <h1> Our Products</h1>

                    </div>



                    {/*Product code begins here -------------------------------------------------------------------*/}
                    {OurProducts}
                    {/*Product code end here -------------------------------------------------------------------*/}

                </div>
            </div>







        </div>

        <Footer />
    </>
    );
}


export default Store;
