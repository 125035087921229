import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { ReactNode, useEffect, useState , useContext  , createContext} from "react";
import "./topHeader2.scss";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useHistory } from "react-router-dom";
import Sidemenu from "../SideMenu/Sidemenu";
import $ from 'jquery';
 
import { Button } from "@material-ui/core";
import PersonModal from "./PersonModal";
import {UserContext}  from '../../App';
import { useDispatch } from "react-redux";
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons";

// interface UserType {
//  user:Array<number|string>
// }



const LoggedOut= function () {
  var history=useHistory();

  useEffect(() => {
    getCart ()                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
  }, [])
    
const scroll=()=>{
  window.scrollTo(0, 0);
 
  
}

const start=()=>{
  $(".navbar-open").css("display", "block");
  $(".navbar-close").css("display", "none");
  
}


var div_nav;
var [cancel,setCancel]=useState(false);



const open_nav=()=>{
  console.log("running")
//  $(".navbar-open").css("display", "none");
 // $(".navbar-close").css("display", "block");


 $(".navbar-collapse").fadeIn();
 $(".navbar-open").css("display", "none");
 $(".navbar-close").css("display", "block");

 
}

const close_nav=()=>{
//  $(".navbar-open").css("display", "block");
 // $(".navbar-close").css("display", "none");

 $(".navbar-collapse").fadeOut();
 $(".navbar-open").css("display", "block");
 $(".navbar-close").css("display", "none");


}





  //const {state,dispatch}= useContext(UserContext);
  const {state,dispatch}= useContext(UserContext);
  var [login_done,setLogin]=useState(true);
  var [login_done2,setLogin2]=useState(true);
  var [counter,setCounter]= useState('');
 
if(localStorage.getItem("numberOfItems")==null){
  localStorage.setItem("numberOfItems",0);
}
//const {counter,dispatch2}= useContext(UserContext);
const {state2,dispatch2}= useContext(UserContext);
  async function getCart() {
  try{
  fetch('https://api.scratchnest.com/cart', {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
      },
      credentials:'include',
  }).then(res => res.json()).then(res => {
      if (!res.user) {
        if(JSON.parse(localStorage.getItem("products"))){
        setCounter(JSON.parse(localStorage.getItem("products")).length);}
      }else{
        //setCounter(res.user.cart.items.length);
        localStorage.setItem("numberOfItems",res.user.cart.items.length);
      }
  })
      .catch(err => {
          console.log(err);
      });
}catch(err){
console.log(err)
}
}
 // const [{ user,basket }] = useStateValue();
 // const [open, setOpen] = useState(false);
 // const history = useHistory();
 // const [hover, setHover] = useState(false);
 // var [displayname, setDisplayame] = useState("");

 // useEffect(() => {
 //   setDisplayame(user?.displayName);
//  }, [user]);

 // const onHover = () => {
 ///   setHover(true);
 // };

  //const onLeave = () => {
 //   setHover(false);
 // };

 // const Signin = () => {
 //   if (user) {
 //     auth.signOut();
  //  } else history.push("/account");
 // };


 // console.log(user);
 const [open, setOpen] = useState(false);
 

  return (

    <div className="topheader2"  >
  {/*
  
<nav className="navbar navbar-expand-md bg-dark navbar-dark">
  <a className="navbar-brand" href="#">
  <img
              src="https://res.cloudinary.com/dpysmqax5/image/upload/v1615074337/logo_white_solid__copy_dqpf6l.png"
              className="logofooter"
            />
  </a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="collapsibleNavbar">
    <ul className="navbar-nav" id="branddiv" >
    <Link to="/sector/datalogger" id="brand">
     
      <li className="nav-item" >
        <a className="nav-link" href="#" id="brand">RFID</a>
      </li>
     </Link>
      <Link to="/sector/datalogger" id="brand">
     
      <li className="nav-item" >
        <a className="nav-link" href="#" id="brand">Beacon</a>
      </li>
      </Link>
      <Link to="/sector/datalogger" id="brand">
     
      <li className="nav-item" >
        <a className="nav-link" href="#" id="brand">DataLogger</a>
      </li>    
      </Link>
      <Link to="/sector/datalogger" id="brand">
     
      <li className="nav-item">
        <a className="nav-link" href="#" id="brand">Education</a>
      </li>        
     </Link>     
    </ul>
    
    
    
    
    <button className="btn btn-outline-primary" onClick={() => setOpen(!open)} id="btn"> Cart</button>
    
 
  </div>  
 
  {open && <Sidemenu open={open} setOpen={setOpen} />}
  
</nav>
  */}

<div >

<nav className="navbar navbar-expand-* bg-dark navbar-dark">
  <a className="navbar-brand" href="#">
<Link to="/">
  <img onClick={scroll}
              src="https://res.cloudinary.com/dpysmqax5/image/upload/v1615074337/logo_white_solid__copy_dqpf6l.png"
              className="logofooter"
            />
            </Link>
  </a>
  
  
  
  <ul className="navbar bg-dark" id="branddiv" >
    <Link to="/sector/rfid">
     
      <li className="nav-item" onClick={scroll}>
        <a className="nav-link" href="#">RFID</a>
      </li>
     </Link>
      <Link to="/sector/Beacon" >
     
      <li className="nav-item" onClick={scroll}>
        <a className="nav-link" href="#" >Beacon</a>
      </li>
      </Link>
      <Link to="/sector/datalogger" onClick={scroll}>
     
      <li className="nav-item" >
        <a className="nav-link" href="#" >DataLogger</a>
      </li>    
      </Link>
      <Link to="/sector/Education" onClick={scroll}>
     
      <li className="nav-item">
        <a className="nav-link" href="#">Education</a>
      </li>        
     </Link>     
    </ul>
    <div className="row navbarrow">
 {/* <div className="col">
     <Link to="/store" > 

<button className="btn "  onClick={() => {setOpen(!open);scroll();}} id="btn"> <i class="fa fa-shopping-bag"></i> </button>

</Link> 
</div>
*/}

<div className="col">

<div id="desktop_nav">
{/* this is our login button this will appear until the user logins------------------------------------------------------------*/}
   
<button className="btn btn-outline-danger" id="loginbutton" style={{display:'block'}} onClick={()=>{history.push("/login");scroll();}}  >LOGIN </button>


{/* this is our profile  button this will appear until the user logouts------------------------------------------------------------*/}

<button  style={{display:'none'}} className="btn circled" type="button" onClick={()=>{history.push("/profile");scroll();}}  id="userbutton" ><i className="fa fa-user-circle-o"  ></i></button>
{/**------------------------------------------------------------------------------------------------------------------------------------------------ */}



</div>
</div>
<div className="col">
<button className="navbar-toggler navbar-open desknav" type="button"     onClick={open_nav}>
    <span className="navbar-toggler-icon" ></span>
   
    
  </button>

  <button className="navbar-toggler navbar-close desknav" type="button"     onClick={close_nav}>
    <span className="navbar-toggler-icon" ></span>
   
    
  </button> 
  






</div>
  </div>
    
  </nav>




  <div className="collapse navbar-collapse collapsibleNavbar "  id="collapsibleNavbar"  onMouseLeave={close_nav} >
  


<div className="container-fluid">
<div className="row row2">




<div className="col-sm-12 col-md-12 col-12 navbar-nav" id="col1">

<i class="fa fa-close phonenav" onClick={close_nav} style={{"color":"grey","float":"right","right":"0px","textAlign":"right","fontSize":"30px"}}></i>

<p id="producthead"> Our Products</p>
<ul style={{"textAlign":"left"}} id="ul1" className="navbar-nav">


  <table>
    <tr>
  
  <a type="button" data-toggle="collapse" data-target="#demo" className="heading1 nav-item"  > 
  RFID     <i className="fa fa-angle-double-down" ></i></a>
  
  </tr><tr>
  <div id="demo" className="collapse" >
   
    <li onClick={()=>{history.push("/products/rfid/oneport");scroll();}} className="nav-item"> 
 RFID One Port Integrated Reader
  </li>


    <li  onClick={()=>{history.push("/products/rfid/twoport");scroll();}}>  
    RFID  Two Port Integrated Reader

    </li>

    
    <li onClick={()=>{history.push("/products/Rfid/fixedReader_Twoport");scroll();}}>
    RFID Two Port Fixed Reader
    </li>
    <li onClick={()=>{history.push("/products/Rfid/fixedReader_Fourport");scroll();}}>
    RFID  Four Port Fixed Reader
    </li>
    <li onClick={()=>{history.push("/products/LinearAntenna");scroll();}}>  
    RFID Linear Polarized Antenna
    </li>

    <li onClick={()=>{history.push("/products/CircularAntenna");scroll();}}>  
   RFID Circular Polarized Antenna
    </li>
 
    
  </div>
  </tr></table>

</ul>



<ul style={{"textAlign":"left"}} id="ul1" className="navbar-nav"> 
  <table>
    <tr>
  
  <a type="button" data-toggle="collapse" data-target="#demo1" className="heading1 nav-item" > 
  Beacon   <i className="fa fa-angle-double-down" ></i></a>
  
  </tr><tr>
  <div id="demo1" className="collapse" >
    <li onClick={()=>{history.push("/products/Beacon");scroll();}}>  
    Tracking Beacon
    </li>


 
    
  </div>
  </tr></table>

</ul>





<ul style={{"textAlign":"left"}} id="ul1" className="navbar-nav">
  <table>
    <tr>
  
  <a type="button" data-toggle="collapse" data-target="#demo3" className="heading1 nav-item" > 
  DataLogger   <i className="fa fa-angle-double-down" ></i></a>
  
  </tr><tr>
  <div id="demo3" className="collapse" >
    <li onClick={()=>{history.push("/products/logger");scroll();}}>  
    AmbiTag
    </li>


 
    
  </div>
  </tr></table>

</ul>




<ul style={{"textAlign":"left"}} id="ul1" className="navbar-nav">
  <table>
    <tr>
  
  <a type="button" data-toggle="collapse" data-target="#demo4" className="heading1 nav-item" > 
  NFC <i className="fa fa-angle-double-down" ></i></a>
  
  </tr><tr>
  <div id="demo4" className="collapse" >
  <li >  
    Coming Soon
    </li>

 
    
  </div>
  </tr></table>

</ul>

<hr></hr>

</div>





<div className="col-sm-12 col-md-12 col-6" id="tempnav">
 
 <ul className="navbar-nav">
 <Link to="/sector/rfid">
     
     <li className="nav-item" >
       <a className="nav-link" href="#">RFID</a>
     </li>
    </Link>
     <Link to="/sector/Beacon" >
    
     <li className="nav-item" >
       <a className="nav-link" href="#" >Beacon</a>
     </li>
     </Link>
     <Link to="/sector/datalogger">
    
     <li className="nav-item" >
       <a className="nav-link" href="#" >DataLogger</a>
     </li>    
     </Link>
     <Link to="/sector/Education" >
    
     <li className="nav-item">
       <a className="nav-link" href="#">Education</a>
     </li>        
    </Link>     

 </ul>
 
</div>



    <div className="col-sm-12 col-md-12 col-6">
 
    <ul className="navbar-nav rightnav">
    <Link to="/cart" >
   
      <li className="nav-item" onClick={scroll}>
        <a className="nav-link" href="#">Cart</a>
      </li>

      </Link>
      
    <Link to="/about" >
   
      <li className="nav-item" onClick={scroll}>
        <a className="nav-link" href="#">About Us</a>
      </li>

      </Link>

      <Link to="/blog" >
   
   <li className="nav-item" onClick={scroll}>
     <a className="nav-link" href="#">Blogs</a>

   </li>    
</Link>


      <Link to="/contact" >
   
      <li className="nav-item " onClick={scroll}>
        <a className="nav-link" href="#">Contact Us</a>
   
      </li>    
   </Link>
   <Link to="/support" >

   <li className="nav-item" onClick={scroll}>
     <a className="nav-link" href="#">Support</a>
   </li>

   </Link>
   <Link to="/store" >

<li className="nav-item" onClick={scroll}>
  <a className="nav-link" href="#">Store</a>
</li>

</Link>

    </ul>
    
   </div>

   
   <div className="col-sm-12 col-md-12 col-12" id="phone_login">

{/* this is our login button this will appear until the user logins------------------------------------------------------------*/}

<button className="btn btn-danger" id="loginbutton" style={{display:'block'}}   onClick={()=>{history.push('/login');scroll();}}>LOGIN </button>

{/* this is our profile  button this will appear until the user logouts------------------------------------------------------------*/}

<button  style={{display:'none'}} className="btn circled" type="button" onClick={()=>{history.push('/profile');scroll();}}  id="userbutton" ><i className="fa fa-user-circle-o"  > Profile</i></button>
{/**------------------------------------------------------------------------------------------------------------------------------------------------ */}
 
 
 
</div>



</div>



</div>

  </div>  



</div>

  






{/*----------------------------------------phone nav---------------------------------------------------------------------------------------  */}
























    </div>

  );
}

export default LoggedOut;
