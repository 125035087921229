
import img1 from './image/img1.png';
import img2 from './image/img2.png';
import img3 from './image/img3.png';
import img4 from './image/img4.png';
import img5 from './image/img5.png';
import img6 from './image/img6.png';
import img7 from './image/img7.png';
import img8 from './image/img8.png';
import img9 from './image/img9.png';

import img10 from './image/img10.png';
import img11 from './image/img11.png';
import img12 from './image/img12.png';
import img13 from './image/img13.png';




 export const OurItems=[
    {
        "Item_name":"Wifi",
        "Item_type":"CC3220 DEV Board",
        "path":"/CC3220",
        "image":img1,
        "Description": "A developed kit, designed with the latest technology and with enhanced and advanced security features to ensure a secure and stable connection, brought to you by ScratchNest. It comes with a single-chip wireless microcontroller (MCU). With the kit, you can have a brand-new experience in terms of connection and security .Moreover, the development kit is designed with 1MB Flash memory, 256 KB RAM, and 32-bit data bus width. The kit is compact in design with only 80 mm in length and 71 mm in width. It consists of 27 I/O. To initiate the operations, the prescribed voltage range should be 2.1 to 3.6 volts."
       , "price":"1225"
    }
    ,{
        "Item_name":"Micro controller",
        "Item_type":"STM32L412 DEV Board",
        "path":"/STM32L412KB",
        "image":img2,
        "Description":"   The board is designed considering the experimental mindset of the learners so that they can experiment with new concepts and learn accordingly. It ensures great performance and is designed with unique features including power consumption features. The board can also be used to build prototypes.ScratchNest has designed this microcontroller with 128 KB Flash program memory and 40 KB SRAM data RAM to ensure flawless performance. WIth 32 bits data Bus width, this board requires a voltage range starting from 1.71 to 3.6 volts to operate.",
         "price":"1225"
    },
    {
        "Item_name":"Memory",
        "Item_type":"W25Q16JV Breakout Board",
        "path":"/W25Q16JV",
        "image":img3,
        "Description":"Another breakout board is launched by the team of ScratchNest, specifically designed for the purpose of the integration of Flash memory into any system without any trouble and complications.The 30 mm long and 16 mm wide breakout board is a compact gadget and also comes with a very long data retention time period of more than 20 years. The 16 MB non-volatile Flash memory has the ability to transfer data at a very high speed of 66 MB per second which ensures speedy performance.",
         "price":"1225"
    },
    {
        "Item_name":"Motion",
        "Item_type":"LIS3DH Breakout Board",
        "path":"/LIS3DH",
        "image":img4,
        "Description":"It is a three-axis linear accelerometer designed and manufactured by the team of ScratchNest that ensures flawless and high performance. It can be considered a member of the ‘nano’ family and one of the most important reasons to consider it as one of the remarkable electronic gadgets of today’s world is that it consumes less battery and power.Designed with I2C, SPI interface, the accelerometer can measure accelerations with output data rates ranging from 1 to 5.3 kHz. It is a compact gadget with 30 mm length and 16 mm width and can operate within the temperature range of -40 to 85 degrees celsius and voltage ranging from 1.71 to 3.6 volts. ",
         "price":"1225"
    }
    
    ,
    {
        "Item_name":"Temp \n Humidity",
        "Item_type":"SHT40 Breakout Board",
        "path":"/SHT40-AD1B-R3",
        "image":img5,
        "Description":"A unique digital sensor platform brought to you by ScratchNest that can measure and track relative humidity and temperature and that too, at different accuracy levels. It is designed with a 12C interface that makes it a sustainable and affordable option for buyers. The latest 4th generation technology has been used to manufacture the sensor so that it can seamlessly in high as well as low-frequency ranges and at the same time, consumes less power. The pins are ideal for uninterrupted SMT (Surface Mount Technology) processing.  The 16.5 mm long and 22 mm wide sensor works within the range of -40 to 125 degrees celsius and the suggested voltage range required for its operation is 1.08 to 3.6 volts. "
         , "price":"1225"
    }

    ,
    {
        "Item_name":"Temp \n Humidity",
        "Item_type":"STTS751 Breakout Board",
        "path":"/STTS751",
        "image":img6,
        "Description":" A digital temperature sensor designed and manufactured by the most talented engineers of ScratchNest. The sensor can be used in multiple smart electronic gadgets and devices like smart batteries, portable electronic gadgets, and also in notebook computers as well. The sensor is compatible with a 2-wire SMBus and can communicate with the same.The sensor is designed with 9 and 12 bits user-configurable resolution that can measure the temperature. The 9 bits resolution can measure the temperature as low as 0.5 degrees celsius and the 12 bits resolution can measure temperature that ranges from 0.0625 degrees celsius.      The 17 mm long and 25 mm wide temperature sensor measures temperature that ranges from -40 to 125 degrees celsius and it can track the temperature almost accurately with a slight variation of -2 degrees celsius. The voltage range required for the seamless operation of the sensor ranges from 2.25 to 3.6 volts."
         ,"price":"1225"
    },

    {
        "Item_name":"Temp \n Humidity",
        "Item_type":"HTS221 Breakout Board",
        "path":"/HTS221",
        "image":img7,
        "Description":"It is a breakout board for a Flash memory intended to be used for humidity and temperature monitoring sensors, introduced by ScratchNest. It is designed with compact and ultra-modern technology to ensure that the sensors track and monitor the relative humidity and temperature effortlessly. Moreover, it is designed with all the required pins to integrate Flash memory into any system without any sort of interruption. It comes with a 16-bit resolution and the breakout board takes only 10 seconds to respond. It can measure temperature that ranges from -40 to 125 degrees celsius. The breakout board is quite compact as it is only 16.5 mm long and 30 mm wide. The operating voltage for the breakout board ranges from 1.7 to 3.6 volts.",
         "price":"1225"
    },
    
    {
        "Item_name":"Air \n Quality",
        "Item_type":"BME680 Breakout Board",
        "path":"/BME680",
        "image":img8,
        "Description":"ScratchNest has launched a 4-in-1 sensor to detect gas, humidity, temperature, and pressure based on the prescribed or proven sensing principles. The sensor is compact in design with only 17 mm length and 30 mm width and it also consumes less power. That is why it is perfectly suited to be integrated into any battery-powered devices like hand watches and also into other frequency-coupled devices.The sensor can detect the presence of humidity in the surrounding area in 8 seconds and takes only 1 second to detect the presence of gas. The operating voltage ranges from 1.71 to 3.6 volts for the sensor.",
         "price":"1225"
    },
    {
        "Item_name":"Grove \n Shield",
        "Item_type":"Grove Shield",
        "path":"/Grove_Shield",
        "image":img9,
        "Description":"Now, you can easily connect with MCU boards with the help of the Grove shield manufactured and designed by ScratchNest. There are 16 Grove connectors on the Grove shield so that you can easily connect and experiment with the Arduino boards.The Grove shield can operate within the voltage range of 3.3 volts to 5 volts. Designed with one LED, the Grove Shield is 81.5 mm long and 54.1 mm wide. Also, the shield can operate within the temperature range of -40 degrees to 85 degrees celsius. The Grove shield is compatible with Arduino uno boards that are specifically designed to read the inputs and make the learning process easier.",
         "price":"1225"
    },
    

    {
        "Item_name":"Relay",
        "Item_type":"Relay",
        "path":"/RL-Z12D-U",
        "image":img10,
        "Description":"A breakout board designed with input and operating contact terminals to initiate single as well as multiple control signals and to operate the process of building and breaking of multiple contacts. Designed and manufactured by ScratchNest, the breakout board is crafted with the latest technology to ensure a secure connection between the data transmitter and the receiver and also to ensure the stable interchange of information between them. Most of the smart electronic devices that we use nowadays are designed with this relay breakout board like air conditioners, microwaves, card readers, etc. for its ability to operate the system simultaneously.   The maximum switching current for the breakout board is 250V AC and the maximum switching current is 5A. It comes with a long electrical shelf life of up to 50,000 Operations and a longer mechanical shelf life of 10,000,000 operations. The length of the breakout board is 46 mm and it is 38.5 mm wide and it operates within the temperature range of -40 degrees to 85 degrees celsius.",
         "price":"1225"
    },
    {
        "Item_name":"Detector",
        "Item_type":"CNG Detector \n Sensor",
        "path":"/CNG_Detector",
        "image":img11,
        "Description":"  ScratchNest has come up with another breakout board, specifically designed to detect gas leakage. It is manufactured for industrial as well as domestic purposes as the breakout board can detect any sort of gas and smoke emitting from LPG, fumes, and cigarettes, etc.It can detect temperature and humidity as well. The breakout board can work within the temperature range of -20 to 70 degrees celsius. It is 27.2 mm long and 37 mm wide which makes it consume less space and comes with 10KΩ- 60KΩ sensing resistance.",
         "price":"1225"
    },
    {
        "Item_name":"Proximity",
        "Item_type":"VL53L3CX Breakout Board",
        "path":"/VL53L3CX",
        "image":img12,
        "Description":"It is one of the best ToF (time of flight) products designed and manufactured by ScratchNest. Designed with the latest ultra-modern technology, this will provide you with a never-before detection experience. With the help of this proximity sensor, now you can easily detect and identify any object that is placed or situated within a short distance. It is designed with a high-performance proximity and ranging sensor to ensure accurate identification of an object. Also, it comes with multi-target distance measurements and automatic smudge correction so that you can identify and detect the presence of multiple objects without any additional hassle or difficulty. The operating voltage range of this proximity sensor is 2.6 to 3.5 volts with a clock frequency of 1 GHz. It can detect any object within a distance of 5 meters. The electronic gadget is 16 mm long and 25 mm wide and operates within the temperature range of -20 degrees celsius to 85 degrees celsius. With its optical sensing method, it can capture detailed and accurate information about the presence of an object.",
         "price":"1225"
    },
    
    {
        "Item_name":"Buzzer",
        "Item_type":"Buzzer",
        "path":"/Buzzer",
        "image":img13,
        "Description":"    A breakout board, designed and manufactured by ScratchNest that can identify different frequencies to make a sound on the basis of that. It will make the process of installation easier for you with electronic devices. The breakout board is 25 mm long and 18.5 mm wide with a 12 mm diameter. The operating voltage range for the breakout board is 2.5 volts to 6.0 volts. The temperature range of the breakout board is –40°C to +85°C. It is designed with such components that can easily break down all the conductors to terminals and also provides you with the opportunity to experiment with the integrated circuit in a better way. It comes with a 2.048 kHz clock frequency and 85dBA sound output."
        ,  "price":"1225"
    },
    

    ]
    




export default OurItems;
