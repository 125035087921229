import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { ReactNode, useEffect, useState, useContext, createContext } from "react";
import "./topHeader2.scss";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useHistory } from "react-router-dom";
import Sidemenu from "../SideMenu/Sidemenu";
import { Button } from "@material-ui/core";
import PersonModal from "./PersonModal";
import { UserContext } from '../../App';
import BarLoader from "react-spinners/HashLoader";
import { Modal, Form } from "react-bootstrap";
//import success from './success.png';
import $ from 'jquery';
// interface UserType {
//  user:Array<number|string>
// }

function LoggedIn() {
  var history = useHistory();
  useEffect(() => {
    getCart()
  }, [])

  const scroll = () => {
    window.scrollTo(0, 0);
  }


  /*------------------------------------------------ loader functions   start here*/
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
    $("body").css("pointer-events", "none");
  }

  const handleClose = () => {
    setShow(false)
    //redireted to login page 
    $("body").css("pointer-events", "auto");


  }


  //-------------------------------------------------- loader function ends here


  const { state2, dispatch2 } = useContext(UserContext);
  const { state, dispatch } = useContext(UserContext);
  var [cancel, setCancel] = useState(true);
  var [collapsed, setCollapse] = useState(true);
  var [login_done, setLogin] = useState(true);
  var [login_done2, setLogin2] = useState(true);

  const [status, setStatus] = useState('Closed');



  const open_nav = () => {
    console.log("running")
    //  $(".navbar-open").css("display", "none");
    // $(".navbar-close").css("display", "block");

    $(".acc-collapse").css("display", "none");
    $(".acc-open").css("display", "block");
    $(".acc-close").css("display", "none");


    $(".navbar-collapse").fadeIn();
    $(".navbar-open").css("display", "none");
    $(".navbar-close").css("display", "block");


  }

  const close_nav = () => {
    //  $(".navbar-open").css("display", "block");
    // $(".navbar-close").css("display", "none");




    $(".navbar-collapse").fadeOut();
    $(".navbar-open").css("display", "block");
    $(".navbar-close").css("display", "none");


  }




  const open_acc = () => {
    console.log("running")
    //  $(".navbar-open").css("display", "none");
    // $(".navbar-close").css("display", "block");

    $(".navbar-collapse").css("display", "none");
    $(".navbar-open").css("display", "block");
    $(".navbar-close").css("display", "none");

    $(".acc-collapse").fadeIn();
    $(".acc-open").css("display", "none");
    $(".acc-close").css("display", "block");


  }

  const close_acc = () => {
    //  $(".navbar-open").css("display", "block");
    // $(".navbar-close").css("display", "none");

    $(".acc-collapse").fadeOut();
    $(".acc-open").css("display", "block");
    $(".acc-close").css("display", "none");


  }





  const [open, setOpen] = useState(false);
  var [counter, setCounter] = useState('');
  var [loader, setLoading] = useState(false);





  var l = <BarLoader color="red" size={50} style={{ "position": "relative" }} />;


  const Logout = () => {
    console.log("runnning lgout");

    fetch('https://api.scratchnest.com/logout', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include'
    }).then(res => res.json()).then(res => {
      console.log(res);
      if (res.status == true) {
        setLoading(true);
        /*------------------------------loader start------------------------------------*/
        handleShow();  //lader calling function
        localStorage.setItem("numberOfItems", 0);
        getCart()
        //setCounter(0);
        //-----------------------------------------------------------sending data to reducer and payload set to false---------------
        //dispatch({ type: "USER", payload: false });
        //--------------------------------------------------------------------------------------------------
      }
    })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });

  }

  const getCart = () => {
    try {

      setLoading(true);
      fetch('https://api.scratchnest.com/cart', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include'
      }).then(res => res.json()).then(res => {
        if (res.user) {
          dispatch({ type: "USER", payload: true });
          setCounter(res.user.cart.items.length);
          localStorage.setItem("numberOfItems", res.user.cart.items.length);
          dispatch2({ type: 'INCREMENT' });
        } else if (JSON.parse(localStorage.getItem("products"))) {
          dispatch({ type: "USER", payload: false });
          setCounter(JSON.parse(localStorage.getItem("products")).length);
        } else {
          /*---------------------loader ends------------------------*/
          handleClose();
          setLoading(false);
          dispatch({ type: "USER", payload: false });
          localStorage.setItem("numberOfItems", 0);
        }
      })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err)
    }
  }

  //  getCart();

  return (




    <div className="topheader2">
      {/* ----------------------------------------------loader modal added here ----------------------------------*/}
      <Modal show={show} className="modal" onHide={() => setShow(false)}

        aria-labelledby="contained-modal-title-vcenter" style={{ "padding": "3%", "height": "100vh", "width": "100vw", "opacity": "0.8" }}
        centered
      >
        <div className="container successmodal" style={{ "textAlign": "center", "width": "80%", "margin": "10%" }}>
          <div>
            {l}
          </div>
          <br></br>   <br></br>
          <h2 className="text-danger" style={{ "textAlign": "center" }}> logging out</h2>


        </div>



      </Modal>
      {/* ----------------------------------------------loader modal ends here ----------------------------------*/}
      {/*
  
<nav className="navbar navbar-expand-md bg-dark navbar-dark">
  <a className="navbar-brand" href="#">
  <img
              src="https://res.cloudinary.com/dpysmqax5/image/upload/v1615074337/logo_white_solid__copy_dqpf6l.png"
              className="logofooter"
            />
  </a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="collapsibleNavbar">
    <ul className="navbar-nav" id="branddiv" >
    <Link to="/sector/datalogger" id="brand">
     
      <li className="nav-item" >
        <a className="nav-link" href="#" id="brand">RFID</a>
      </li>
     </Link>
      <Link to="/sector/datalogger" id="brand">
     
      <li className="nav-item" >
        <a className="nav-link" href="#" id="brand">Beacon</a>
      </li>
      </Link>
      <Link to="/sector/datalogger" id="brand">
     
      <li className="nav-item" >
        <a className="nav-link" href="#" id="brand">DataLogger</a>
      </li>    
      </Link>
      <Link to="/sector/datalogger" id="brand">
     
      <li className="nav-item">
        <a className="nav-link" href="#" id="brand">Education</a>
      </li>        
     </Link>     
    </ul>
    
    
    
    
    <button className="btn btn-outline-primary" onClick={() => setOpen(!open)} id="btn"> Cart</button>
    
 
  </div>  
 
  {open && <Sidemenu open={open} setOpen={setOpen} />}
  
</nav>
  */}



      <nav className="navbar navbar-expand-* bg-dark navbar-dark">
        <a className="navbar-brand" href="#">
          <Link to="/">
            <img onClick={scroll}
              src="https://res.cloudinary.com/dpysmqax5/image/upload/v1615074337/logo_white_solid__copy_dqpf6l.png"
              className="logofooter"
            />
          </Link>
        </a>



        <ul className="navbar bg-dark" id="branddiv" >
          <Link to="/sector/rfid">

            <li className="nav-item" onClick={scroll}>
              <a className="nav-link" href="#">RFID</a>
            </li>
          </Link>
          <Link to="/sector/Beacon" >

            <li className="nav-item" onClick={scroll}>
              <a className="nav-link" href="#" >Beacon</a>
            </li>
          </Link>
          <Link to="/sector/datalogger">

            <li className="nav-item" onClick={scroll}>
              <a className="nav-link" href="#" >DataLogger</a>
            </li>
          </Link>
          <Link to="/sector/Education" >

            <li className="nav-item" onClick={scroll}>
              <a className="nav-link" href="#">Education</a>
            </li>
          </Link>
        </ul>
        <div className="row navbarrow">
          {/*
        <div className="col">
  <Link to="/store" > 

<button className="btn "  onClick={() => {setOpen(!open);scroll();}} id="btn"> <i class="fa fa-shopping-bag"></i> </button>

</Link>
</div>

     */}

          <div className="col">
            <Link to="/cart" >
              {/*------------------------------------------------------------Cart Button ----------------------------------------*/}
              <button className="btn " onClick={() => { setOpen(!open); scroll(); }} id="btn">       <sup style={{ "fontSize": "0.5em", "float": "right", "textAlign": "right", "marginRight": "-10px", "marginBottom": "-30px" }}>  {/*counter*/} {state2} {/*number of items */} </sup>   <i class="fa fa-shopping-cart" ></i> </button>

            </Link>
          </div>
          <div className="col">
            <div id="desktop_nav">


              {/* this is our login button this will appear until the user logins------------------------------------------------------------*/}

              <button className="btn btn-danger" id="loginbutton" style={{ display: 'none' }} onClick={() => { history.push("/login"); scroll(); }}>LOGIN </button>

              {/* this is our profile  button this will appear until the user logouts , it will open our dropdown  , LogOut button code at the bottom------------------------------------------------------------*/}

              <button className="btn circled acc-open " onClick={open_acc} type="button"    ><i className="fa fa-user-circle-o"  ></i></button>
              <button className="btn circled acc-close " onClick={close_acc} type="button"    ><i className="fa fa-user-circle-o"  ></i></button>


              {/**------------------------------------------------------------------------------------------------------------------------------------------------ */}

            </div>

          </div>

          <div class="col">
            <button className="navbar-toggler navbar-open desknav" type="button" onClick={open_nav}>
              <span className="navbar-toggler-icon" ></span>


            </button>

            <button className="navbar-toggler navbar-close desknav" type="button" onClick={close_nav}>
              <span className="navbar-toggler-icon" ></span>


            </button>
          </div>

        </div>

      </nav>







      <div className="collapse navbar-collapse" id="collapsibleNavbar" onMouseLeave={close_nav} >

        <div className="container-fluid">
          <div className="row row2">




            <div className="col-sm-12 col-md-12 col-12 navbar-nav" id="col1">

              <i class="fa fa-close phonenav" onClick={close_nav} style={{ "color": "grey", "float": "right", "right": "0px", "textAlign": "right", "fontSize": "30px" }}></i>


              <p id="producthead"> Our Products</p>
              <ul style={{ "textAlign": "left" }} id="ul1" className="navbar-nav">


                <table>
                  <tr>

                    <a type="button" data-toggle="collapse" data-target="#demo" className="heading1 nav-item"  >
                      RFID     <i className="fa fa-angle-double-down" ></i></a>

                  </tr><tr>
                    <div id="demo" className="collapse" >

                      <li onClick={() => { history.push("/products/rfid/oneport"); scroll(); }} className="nav-item">
                        RFID One Port Integrated Reader
                      </li>


                      <li onClick={() => { history.push("/products/rfid/twoport"); scroll(); }}>
                        RFID  Two Port Integrated Reader

                      </li>


                      <li onClick={() => { history.push("/products/Rfid/fixedReader_Twoport"); scroll(); }}>
                        RFID Two Port Fixed Reader
                      </li>
                      <li onClick={() => { history.push("/products/Rfid/fixedReader_Fourport"); scroll(); }}>
                        RFID  Four Port Fixed Reader
                      </li>
                      <li onClick={() => { history.push("/products/LinearAntenna"); scroll(); }}>
                        RFID Linear Polarized Antenna
                      </li>

                      <li onClick={() => { history.push("/products/CircularAntenna"); scroll(); }}>
                        RFID Circular Polarized Antenna
                      </li>


                    </div>
                  </tr></table>

              </ul>



              <ul style={{ "textAlign": "left" }} id="ul1" className="navbar-nav">
                <table>
                  <tr>

                    <a type="button" data-toggle="collapse" data-target="#demo1" className="heading1 nav-item" >
                      Beacon   <i className="fa fa-angle-double-down" ></i></a>

                  </tr><tr>
                    <div id="demo1" className="collapse" >
                      <li onClick={() => { history.push("/products/Beacon"); scroll(); }}>
                        Tracking Beacon
                      </li>




                    </div>
                  </tr></table>

              </ul>





              <ul style={{ "textAlign": "left" }} id="ul1" className="navbar-nav">
                <table>
                  <tr>

                    <a type="button" data-toggle="collapse" data-target="#demo3" className="heading1 nav-item" >
                      DataLogger   <i className="fa fa-angle-double-down" ></i></a>

                  </tr><tr>
                    <div id="demo3" className="collapse" >
                      <li onClick={() => { history.push("/products/logger"); scroll(); }}>
                        AmbiTag
                      </li>




                    </div>
                  </tr></table>

              </ul>




              <ul style={{ "textAlign": "left" }} id="ul1" className="navbar-nav">
                <table>
                  <tr>

                    <a type="button" data-toggle="collapse" data-target="#demo4" className="heading1 nav-item" >
                      NFC <i className="fa fa-angle-double-down" ></i></a>

                  </tr><tr>
                    <div id="demo4" className="collapse" >
                      <li >
                        Coming Soon
                      </li>



                    </div>
                  </tr></table>

              </ul>

              <hr></hr>

            </div>





            <div className="col-sm-12 col-md-12 col-6" id="tempnav">

              <ul className="navbar-nav">
                <Link to="/sector/rfid">

                  <li className="nav-item" >
                    <a className="nav-link" href="#">RFID</a>
                  </li>
                </Link>
                <Link to="/sector/Beacon" >

                  <li className="nav-item" >
                    <a className="nav-link" href="#" >Beacon</a>
                  </li>
                </Link>
                <Link to="/sector/datalogger">

                  <li className="nav-item" >
                    <a className="nav-link" href="#" >DataLogger</a>
                  </li>
                </Link>
                <Link to="/sector/Education" >

                  <li className="nav-item">
                    <a className="nav-link" href="#">Education</a>
                  </li>
                </Link>

              </ul>

            </div>



            <div className="col-sm-6 col-md-12 col-6">

              <ul className="navbar-nav rightnav">
                <Link to="/cart" >

                  <li className="nav-item" onClick={scroll}>
                    <a className="nav-link" href="#">Cart</a>
                  </li>

                </Link>

                <Link to="/about" >

                  <li className="nav-item" onClick={scroll}>
                    <a className="nav-link" href="#">About Us</a>
                  </li>

                </Link>

                <Link to="/contact" >

                  <li className="nav-item" onClick={scroll}>
                    <a className="nav-link" href="#">Contact Us</a>

                  </li>
                </Link>

                <Link to="/blog" >

                  <li className="nav-item" onClick={scroll}>
                    <a className="nav-link" href="#">Blogs</a>

                  </li>
                </Link>



                <Link to="/support" >

                  <li className="nav-item" onClick={scroll}>
                    <a className="nav-link" href="#">Support</a>
                  </li>

                </Link>
                <Link to="/store" >

                  <li className="nav-item" onClick={scroll}>
                    <a className="nav-link" href="#">Store</a>
                  </li>

                </Link>

              </ul>

            </div>


            <div className="col-sm-6 col-md-12 col-12" id="phone_login">

              <button style={{ display: login_done2 ? 'block' : 'none' }} className="btn circled" type="button" onClick={() => { history.push('/profile'); scroll(); }} id="userbutton" ><i className="fa fa-user-circle-o"  > Profile</i></button>
              {/**------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <button className="btn btn-danger" id="loginbutton" style={{ display: 'block', 'color': "white" }} onClick={() => { history.push('/wishlist'); scroll(); }}> My Watchlist </button>
              <button className="btn btn-danger" id="loginbutton" style={{ display: 'block', 'color': "white" }} onClick={() => { history.push('/profile'); scroll(); }}> My Orders </button>

              <button className="btn btn-danger" id="loginbutton" style={{ display: 'block' }} onClick={Logout}> LOGOUT </button>


            </div>



          </div>



        </div>





      </div>


      <div id="login_dropdown" class="collapse acc-collapse" onMouseLeave={close_acc}>
        <ul className="navbar-nav rightnav">
          <Link to="/profile">

            <li className="nav-item" onClick={scroll}>
              <a className="nav-link" href="#">My Account</a>
            </li>

          </Link>

          <Link to="/profile">

            <li className="nav-item" onClick={scroll}>
              <a className="nav-link" href="#">My Orders</a>
            </li>

          </Link>

          <Link to="/wishlist">

            <li className="nav-item" onClick={scroll}>
              <a className="nav-link" href="#">My Wishlist</a>
            </li>

          </Link>



          <Link to="/">

            <li className="nav-item" onClick={scroll}>
              <a className="nav-link" href="#" onClick={Logout}>Logout</a>
            </li>

          </Link>



        </ul>

      </div>




    </div>

  );
}

export default LoggedIn;
