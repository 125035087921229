import Topheader from "../../top_header";

import Footer from '../../Footer/footer2';
import '../store.scss';


import OurItems from '../Ouritems';

import Carousel from '../carousel/STTS75';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import img1 from '../image/STT71/1.png';
import img2 from '../image/STT71/2.png';
import img3 from '../image/STT71/3.png';
import img4 from '../image/STT71/4.png';
import img5 from '../image/STT71/5.png';
import axios from 'axios';
import $ from 'jquery';
import LoadingOverlay from "react-loading-overlay";


import customer from '../customer.png';
import Store2 from '../Store';
import { UserContext } from '../../../App';
import { useContext } from 'react';
import { Modal, Button, Form } from "react-bootstrap";

function Store() {

    var [visible, setVisible] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "STTS751";
        const script = document.getElementById("recaptcha-key");
        if (script) {
            script.remove();
        }
        const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
        if (recaptchaElems.length) {
            recaptchaElems[0].remove();
        }
    }, [])
    var history = useHistory();
    var [search, setSearch] = useState(false);
    var [text, setText] = useState('');
    var [found, setFound] = useState(false);
    var [i1, setI1] = useState(false);
    var [i2, setI2] = useState(false);

    const [rating, setRating] = useState(Number(0));/////////////
    const [headline, setHeadline] = useState();///////////////
    const [review, setReview] = useState('');/////////////////
    const [loader, setLoader] = useState(false);/////////////////

    const { state, dispatch, state2, dispatch2 } = useContext(UserContext);
    var [quant, setQty] = useState(Number(1));
    const [prods, setProducts] = useState(JSON.parse(localStorage.getItem('products')) || []);
    var [totalPrice, setTotalPrice] = useState(JSON.parse(localStorage.getItem('totalPrice')) || 0);

    const [show3, setShow3] = useState(false);
    
    const [show2, setShow2] = useState(false);
    const [show1, setShow1] = useState(false);///////////////
    function handleRedirecting() {
        setShow2(false);
        setShow1(false);/////////////////////
        setShow3(false);
        setLoader(false);
    }
    

    const ShowAll = () => {

        setSearch(false);
        setText('');

    }


    const Hints = OurItems.map((item => {
        var count = 0;

        if (text != "") {
            if (item.Item_name.toLowerCase().indexOf(text.trim().toLowerCase()) >= 0) {
                return (
                    <input type="text" onClick={() => { history.push(item.path) }} value={item.Item_name} />
                )
            }

            if (item.Item_type.toLowerCase().indexOf(text.trim().toLowerCase()) >= 0) {
                return (
                    <input type="text" onClick={() => { history.push(item.path) }} value={item.Item_type} />
                )
            }



        }
    }
    ));



    const increment = () => {
        dispatch2({ type: 'INCREMENT' });
    }
    const decrement = () => {
        dispatch2({ type: 'DECREMENT' });
    }
    const handleChange = (x) => {
        setSearch(true);

        setText(x);

        setFound(false);
    }

    const update_number = () => {
        console.log("running");


    }

    const createSelectItems = () => {
        let items = [];
        for (let i = 1; i <= 10; i++) {
            items.push(<option key={i} value={i} >{i}</option>);
            //here I will be creating my options dynamically based on
            //what props are currently passed to the parent component
        }
        return items;
    }

    const onDropdownSelected = (e) => {
        console.log("THE VAL", e.target.value);
        setQty(parseInt(e.target.value, 10))
        //here you will see the current selected value of the select input
    }


    async function Quantity(id) {
        try {
            fetch('https://api.scratchnest.com/setQuantity', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({
                    "id": id,
                    "price": 1225,
                    "qty": quant
                })

            }).then(res => res.json()).then(res => {
                console.log(res);
                var prod = {}
                if (!res.status == true) {
                    const isExisting = prods.findIndex(objInItems => new String(objInItems.productId).trim() == new String(id).trim());
                    if (isExisting >= 0) {
                        console.log(prods[isExisting]);
                        totalPrice = totalPrice + ((prods[isExisting].price) * (quant - (prods[isExisting].qty)))
                        setTotalPrice(totalPrice)
                        prods[isExisting].qty = quant
                        localStorage.setItem('products', JSON.stringify(prods));
                        localStorage.setItem('totalPrice', JSON.stringify(totalPrice));
                    } else {
                        prod.qty = quant
                        prod.title = "Temp./ \n Humidity"
                        prod.productId = id
                        prod.price = 60
                        prod.description="The STTS751 is a digital temperature sensor which communicates over a 2-wire SMBus 2.0 compatible bus. The temperature is measured with a user-configurable resolution between 9 and 12 bits."
                        prod.imageUrl="https://scratchnest.com/static/media/img6.55139285.png"
                        prod.path="/STTS751"
                        prods.push(prod);
                        totalPrice = totalPrice + (60 * prod.qty)
                        localStorage.setItem('products', JSON.stringify(prods));
                        setTotalPrice(totalPrice)
                        localStorage.setItem('totalPrice', JSON.stringify(totalPrice));
                    }
                    var length = JSON.parse(localStorage.getItem("products")).length
                    localStorage.setItem("numberOfItems", length);
                    dispatch2({type:'INCREMENT'});
                }else{
                    var length = res.user.cart.items.length
                    localStorage.setItem("numberOfItems", length);
                    dispatch2({type:'INCREMENT'});
                }
                setShow2(true)
            })
                .catch(err => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err)
        }
    }



    const OurProducts = OurItems.map((item) => (

        <div className="col-sm-6 col-md-4 col-12" data-aos="zoom-out" onClick={() => { history.push(item.path) }}>
            <div id="box">
                <div id="img_div">
                    <img src={item.image} id="img" />
                </div>

                <div id="heading2">
                    <div className="container-fluid">
                        <div className="row row2">
                            <div className="col-sm-3 col-md-3 col-3">
                                <h2>{item.Item_name}</h2>
                            </div>
                            <div className="col-sm-1 col-md-1 col-1">
                                <div id="line"></div>
                            </div>
                            <div className="col-sm-7 col-md-7 col-7">
                                <h3>{item.Item_type} </h3>
                            </div>
                        </div>

                        <div className="row row3">
                            <div className="col-sm-12 col-md-12 col-12">
                                <p>{item.Description}</p>
                            </div>
                            <div className="col-sm-12 col-md-12 col-12">
                                <h4>₹ {item.price}</h4>
                            </div>
                            <div className="col-sm-6 col-md-6 col-6" >

                                <div class="input-group">
                                    <div className="form-group- form-inline" >
                                        <label id="itemquantity">Qty:</label>
                                        <select onChange={onDropdownSelected} defaultValue={1} >
                                            {createSelectItems()}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-6 col-6">
                                <button className="cart_button button1" onClick={() => { history.push("/order_now?id=614d73e01f4c873258559302") }}>Buy Now</button>
                            </div>


                            <div className="col-sm-12 col-md-12 col-12">
                                <button className="cart_button button2" onClick={() => Quantity('614d73e01f4c873258559302')}><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
                            </div>

                            <div className="col-sm-12 col-md-12 col-12">
                                <button className="cart_button button3" ><i class="fa fa-star"></i>Move to wishlist</button>
                            </div>



                        </div>

                    </div>





                </div>


            </div>
        </div>

    ));




    async function addToWishlist(id) {
        try {
            fetch('https://api.scratchnest.com/addToWishlist', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({
                    "id": id
                })

            }).then(res => res.json()).then(res => {
                console.log(res)
                if (res.status == true) {
                    setShow1(true);
                }
                else {
                    history.push('/login');
                }
            })
                .catch(err => {
                    console.log(err);
             
                });
        } catch (err) {
            console.log(err)
        }
    }

    const handleOnClick = e => {
        e.preventDefault();
      console.log(rating);
      setLoader(true);
        
        submit(e);
    }

      //add review from submit request 
      const submit = (e) => {
        e.preventDefault();
        const payload = {
            id: '614d75aa1f4c873258559316',
            rating: parseInt(rating, 10),
            headline: headline,
            description: review
        };
        console.log(payload);

        axios({
            url: 'https://api.scratchnest.com/do-addReview',
            method: 'POST',
            data: payload,
            withCredentials: true
        }).then((res) => {
            if (res.data.status == true) {
                console.log('Data sent');
               setHeadline('');
               setReview('');
               setRating(Number(0));
               setShow3(true);
              // setLoader(false);
        
               $(".icon").css("color","black");
            }else {
                history.push('/login');
            }
        }).catch((err) => {
            console.log(err);
            setHeadline('');
            setReview('');
            console.log(rating);
          //  setShow3(true);
            setRating(Number(0));
            setLoader(false);
        
            $(".icon").css("color","black");
            
        });
    }











    return (<>

        <div style={{ 'display': visible ? 'block' : 'none' }}>
            <button className="btn btn-outline-danger" style={{ "float": "right", "marginTop": "1%", "marginRight": "1%" }} onClick={() => { setVisible(false) }}> <i class="fa fa-close" style={{ "fontSize": "30px" }}></i></button>
            <Carousel />
        </div>


        <div style={{ 'display': !visible ? 'block' : 'none' }}>
            <Topheader />
            <Modal show={show1} className="modal"

aria-labelledby="contained-modal-title-vcenter" style={{ "padding": "3%" }}
centered
>
<div className="container successmodal" style={{ "textAlign": "center", "width": "80%", "margin": "10%" }}>
    <img src="https://res.cloudinary.com/scratchnest/image/upload/v1629638146/success_rnxfot.png" style={{ "width": "20%" }} />
    <h1 className="text-success" style={{ "textAlign": "center" }}> Success</h1>
    <p> Product Added to Wishlist Successfully   </p>
    <button className="btn btn-lg btn-success" onClick={handleRedirecting} >Proceed</button>
</div>



</Modal>



            <Modal show={show2} className="modal"

                aria-labelledby="contained-modal-title-vcenter" style={{ "padding": "3%" }}
                centered
            >
                <div className="container successmodal" style={{ "textAlign": "center", "width": "80%", "margin": "10%" }}>
                    <img src="https://res.cloudinary.com/scratchnest/image/upload/v1629638146/success_rnxfot.png" style={{ "width": "20%" }} />
                    <h1 className="text-success" style={{ "textAlign": "center" }}> Success</h1>
                    <p> Product Added to Cart Successfully   </p>
                    <button className="btn btn-lg btn-success" onClick={handleRedirecting} >Proceed</button>
                </div>



            </Modal>

            <Modal show={show3} className="modal"

aria-labelledby="contained-modal-title-vcenter" style={{ "padding": "3%" }}
centered
>
<div className="container successmodal" style={{ "textAlign": "center", "width": "80%", "margin": "10%" }}>
    <img src="https://res.cloudinary.com/scratchnest/image/upload/v1629638146/success_rnxfot.png" style={{ "width": "20%" }} />
    <h1 className="text-success" style={{ "textAlign": "center" }}> Success</h1>
    <p> Review Submitted Successfully   </p>
    <button className="btn btn-lg btn-success" onClick={handleRedirecting} >Proceed</button>
</div>



</Modal>
            <div id="iotlab_product">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-md-4 col-12" id="input">

                            <button className="btn btn-secondary btn" onClick={() => { history.push("/store") }}><i class="fa fa-arrow-left" ></i>   Go Back</button>
                        </div>

                        <div className="col-sm-6 col-md-6 col-12" id="input">

                            <input type="text" placeholder="Search for Products..." onChange={(e) => { handleChange(e.target.value) }} value={text} />
                            {Hints}

                        </div>

                    </div>
                </div>


                <div className="container-fluid div2" >
                    <div className="row">
                        <div className="col-sm-4 col-md-5 col-12">


                            <div id="iotlab_img" class="carousel  slide" data-ride="carousel">

                                <ul class="carousel-indicators">
                                    <li data-target="#iotlab_img" data-slide-to="0" class="active"></li>
                                    <li data-target="#iotlab_img" data-slide-to="1"></li>
                                    <li data-target="#iotlab_img" data-slide-to="2"></li>
                                    <li data-target="#iotlab_img" data-slide-to="3"></li>
                                    <li data-target="#iotlab_img" data-slide-to="4"></li>
                                </ul>


                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src={img1} alt="Los Angeles" onClick={() => { setVisible(true) }} />
                                    </div>
                                    <div class="carousel-item">
                                        <img src={img2} alt="Chicago" onClick={() => { setVisible(true) }} />
                                    </div>
                                    <div class="carousel-item">
                                        <img src={img3} alt="New York" onClick={() => { setVisible(true) }} />
                                    </div>
                                    <div class="carousel-item">
                                        <img src={img4} alt="New York" onClick={() => { setVisible(true) }} />
                                    </div>
                                    <div class="carousel-item">
                                        <img src={img5} alt="New York" onClick={() => { setVisible(true) }} />
                                    </div>
                                </div>


                                <a class="carousel-control-prev" href="#iotlab_img" data-slide="prev">
                                    <span class="carousel-control-prev-icon"></span>
                                </a>
                                <a class="carousel-control-next" href="#iotlab_img" data-slide="next">
                                    <span class="carousel-control-next-icon"></span>
                                </a>
                            </div>




                        </div>


                        <div className="col-sm-4 col-md-4 col-12">
                            <div className="div3">
                                <h1> ScratchNest </h1>
                                <h2> Temp./  Humidity </h2>
                                <h3> STTS751 Breakout Board </h3>
                                <p>
                                    Operating Voltage:
                                    2.25V ~ 3.6V,
                                    Standby Current Max:
                                    50 uA,
                                    Accuracy:
                                    +/- 2 C,
                                    Resolution:
                                    12 bit,
                                    Max Clock Frequency:
                                    400 kHz,
                                    Clock Frequency:
                                    133 MHz


                                </p>
                                     {/* this is star rating --------------------*/}
                                     {/*
                                <h4><span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star"></span>
                                    <span class="fa fa-star"></span> 3.0<span> (34 reviews) </span></h4>
                                     */}

     {/* this is star rating --------------------*/}
                            </div>



                        </div>


                        <div className="col-sm-4 col-md-3 col-12" >

                            <div className="div4">


                                <div className="row row3">

                                    <div className="col-sm-12 col-md-12 col-12">
                                        <h4>₹ 60  <span>inc GST</span></h4>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-12">
                                     
                                        <p>Quantity:</p>
                                    </div>

                                    <div className="col-sm-6 col-md-6 col-6" >

                                        <div class="input-group">
                                            <div className="form-group- form-inline" >
                                                <label id="itemquantity">Qty:</label>
                                                <select onChange={onDropdownSelected} defaultValue={1} >
                                                    {createSelectItems()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6 col-6">
                                        <button className="cart_button button1" onClick={() => { history.push("/order_now?id=614d73e01f4c873258559302") }}>Buy Now</button>
                                    </div>


                                    <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button2" onClick={() => Quantity('614d73e01f4c873258559302')}><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-12">
                                        <button className="cart_button button3" onClick={() => addToWishlist('614d73e01f4c873258559302')}><i class="fa fa-star"></i>Move to wishlist</button>
                                    </div>



                                </div>


                            </div>


                        </div>


                        <div className="col-12 col-sm-12 col-md-12">
                            <div id="heading">
                                <h1>Description</h1>
                                <p>
                                A digital temperature sensor designed and manufactured by the most talented engineers of ScratchNest. The sensor can be used in multiple smart electronic gadgets and devices like smart batteries, portable electronic gadgets, and also in notebook computers as well. The sensor is compatible with a 2-wire SMBus and can communicate with the same.

The sensor is designed with 9 and 12 bits user-configurable resolution that can measure the temperature. The 9 bits resolution can measure the temperature as low as 0.5 degrees celsius and the 12 bits resolution can measure temperature that ranges from 0.0625 degrees celsius. 

The 17 mm long and 25 mm wide temperature sensor measures temperature that ranges from -40 to 125 degrees celsius and it can track the temperature almost accurately with a slight variation of -2 degrees celsius. The voltage range required for the seamless operation of the sensor ranges from 2.25 to 3.6 volts.

                                </p>
                            </div>
                        </div>



                        <div className="col-12 col-sm-12 col-md-12">

                            <div id="table">
                                <h1> Technical Specifications</h1>
                                <table>

                                    <tr>
                                        <td id="left"> Based On </td>
                                        <td id="right"> STTS751 from STMicroelectronics</td>
                                    </tr>
                                    <tr>
                                        <td id="left"> Operating Voltage </td>
                                        <td id="right">2.25V ~ 3.6V</td>
                                    </tr><tr>
                                        <td id="left"> Standby Current Max </td>
                                        <td id="right"> 50 uA</td>
                                    </tr><tr>
                                        <td id="left"> Accuracy</td>
                                        <td id="right">+/- 2 C</td>
                                    </tr>
                                    <tr>
                                        <td id="left"> Resolution </td>
                                        <td id="right">12 bit</td>
                                    </tr>
                                    <tr>
                                        <td id="left"> Max Clock Frequency</td>
                                        <td id="right"> 400 kHz</td>
                                    </tr>
                                    <tr>
                                        <td id="left"> Clock Frequency </td>
                                        <td id="right"> 133 MHz</td>
                                    </tr>
                                    <tr>
                                        <td id="left"> Interface </td>
                                        <td id="right"> I2C</td>
                                    </tr>
                                    <tr>
                                        <td id="left"> Temperature Range </td>
                                        <td id="right">–40°C to +125°C  </td>
                                    </tr>


                                    <tr>
                                        <td id="left">Length  </td>
                                        <td id="right">17mm</td>
                                    </tr>
                                    <tr>
                                        <td id="left"> Width </td>
                                        <td id="right"> 25mm</td>
                                    </tr>

                                    <tr>
                                        <td id="left">   Code </td>
                                        <td id="right"> <a   href="/Downloadfile/iot/stt5.zip"
                        target="_blank"
                        rel="noopener noreferrer"
                        download > Get Code</a> </td>
                                    </tr>
                                    
                                    <tr>
                                        <td id="left">  DataSheet </td>
                                        <td id="right"> <a   href="/Downloadfile/iot/stts5.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        download> Get DataSheet </a> </td>
                                    </tr>
                                </table>


                            </div>


                        </div>

                        {/* ----------------------------------cuastomer review code -----------------------------------------*/}
{/*
                        <div className="col-12 col-sm-12 col-md-12 div5">

                            <h1>Customer Reviews</h1>
                            <div className="row row4">
                                <div className="col-sm-6 col-md-6 col-12">
                                    <div className="card">
                                        <table>
                                            <tr>
                                                <td id="img">
                                                    <img src={customer} /></td>
                                                <td><h1> arun sharma </h1></td>
                                            </tr>
                                        </table>

                                        <p><span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star"></span>
                                            <span class="fa fa-star"></span> Great Product </p>

                                        <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</h4>
                                        <br></br>
                                        <h5>Posted 24 December 2021</h5>
                                    </div>

                                </div>

                                <div className="col-sm-6 col-md-6 col-12">
                                    <div className="card">
                                        <table>
                                            <tr>
                                                <td id="img">
                                                    <img src={customer} /></td>
                                                <td><h1> arun sharma </h1></td>
                                            </tr>
                                        </table>

                                        <p><span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star"></span>
                                            <span class="fa fa-star"></span> Great Product </p>

                                        <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</h4>
                                        <br></br>
                                        <h5>Posted 24 December 2021</h5>
                                    </div>

                                </div>

                                <div className="col-sm-6 col-md-6 col-12">
                                    <div className="card">
                                        <table>
                                            <tr>
                                                <td id="img">
                                                    <img src={customer} /></td>
                                                <td><h1> arun sharma </h1></td>
                                            </tr>
                                        </table>

                                        <p><span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star"></span>
                                            <span class="fa fa-star"></span> Great Product </p>

                                        <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</h4>
                                        <br></br>
                                        <h5>Posted 24 December 2021</h5>
                                    </div>

                                </div>


                                <div className="col-sm-6 col-md-6 col-12">
                                    <div className="card">
                                        <table>
                                            <tr>
                                                <td id="img">
                                                    <img src={customer} /></td>
                                                <td><h1> arun sharma </h1></td>
                                            </tr>
                                        </table>

                                        <p><span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star"></span>
                                            <span class="fa fa-star"></span> Great Product </p>

                                        <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</h4>
                                        <br></br>
                                        <h5>Posted 24 December 2021</h5>
                                    </div>

                                </div>

                            </div>



                        </div>
*/}
                        {/* ----------------------------------cuastomer review code end here-----------------------------------------*/}


                        {/* ----------------------------------form code start here-----------------------------------------*/}

                        <div className="col-sm-12 col-md-12 col-12 ">
                        <LoadingOverlay
          active={loader}
     
          spinner={true}
          text="Submiting Review..."
        >
            <div className="review_form">
                            <h1> Post a Review </h1>
                            <form class="form" >
                                <label for="email" id="star">Overall Rating:</label> <br></br>
                                <div class="rating ">

                                    <label>
                                        <input type="radio" name="stars" value="1" onClick={()=>{setRating(1)}}/>
                                        <span class="icon">★</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="stars" value="2" onClick={()=>{setRating(2)}}/>
                                        <span class="icon">★</span>
                                        <span class="icon">★</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="stars" value="3" onClick={()=>{setRating(3)}}/>
                                        <span class="icon">★</span>
                                        <span class="icon">★</span>
                                        <span class="icon">★</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="stars" value="4" onClick={()=>{setRating(4)}}/>
                                        <span class="icon">★</span>
                                        <span class="icon">★</span>
                                        <span class="icon">★</span>
                                        <span class="icon">★</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="stars" value="5" onClick={()=>{setRating(5)}}/>
                                        <span class="icon">★</span>
                                        <span class="icon">★</span>
                                        <span class="icon">★</span>
                                        <span class="icon">★</span>
                                        <span class="icon">★</span>
                                    </label>

                                </div>
                                <br></br>
                                <div class="row">
                                    <div class="col-12">
                                        <label for="email" style={{ display: i1 ? "block" : "none" }}>Enter a Headline:</label></div>
                                    <div class="col-12">

                                    <input type="text" class="form-control" id="email" placeholder="Add a Headline" name="email" value={headline} onMouseUp={() => { setI1(true) }} onChange={e=>setHeadline(e.target.value)} /></div>
                          
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <label for="email" style={{ display: i2 ? "block" : "none" }}>Enter a written Review:</label></div>
                                    <div class="col-12">
                                    <textarea className="md-textarea form-control" minlength="10" placeholder="Add a written Review" value={review} onMouseUp={() => { setI2(true) }}  onChange={e=>setReview(e.target.value)} required />
                       </div>
                                </div>
                                <br></br>


                                <button type="submit" class="btn btn-primary" onClick={handleOnClick}>Submit Review </button>
                            </form>
                            </div>
                            </LoadingOverlay>
                        </div>





                        {/* ----------------------------------form code end here-----------------------------------------*/}




                        <div className="col-12 col-sm-12 col-md-12 div6">

                            <h1>Related Products</h1>


                            <Store2 />
                        </div>

                    </div>


                </div>




                <button className="btn btn-secondary" onClick={() => { window.scrollTo(0, 0) }}>Back To Top</button>


            </div>

            <Footer /></div>
    </>
    );
}


export default Store;
