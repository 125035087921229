
import img1 from '../image/SHT40/1.png';
import img2 from '../image/SHT40/2.png';
import img3 from '../image/SHT40/3.png';
import img4 from '../image/SHT40/4.png';
import img5 from '../image/SHT40/5.png';
import './iot_carousel.scss';
function prod(){
return(<>

<div className="container-fluid" id="iot_Carousel">
    <div className="row">
    <div className="col-sm-12 col-md-12 col-12">


<div id="iotlab_img2" class="carousel carousel-slide slide" data-ride="carousel">

  
  
  
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img1} alt="Los Angeles"  />
    </div>
    <div class="carousel-item">
      <img src={img2} alt="Chicago" />
    </div>
    <div class="carousel-item">
      <img src={img3} alt="New York" />
    </div>
    <div class="carousel-item">
      <img src={img4} alt="New York" />
    </div>
    <div class="carousel-item">
      <img src={img5} alt="New York" />
    </div>
  
  </div>
  
  
  <a class="carousel-control-prev" href="#iotlab_img2" data-slide="prev">
    <span class="fa fa-angle-double-left" ></span>
  </a>
  <a class="carousel-control-next" href="#iotlab_img2" data-slide="next">
    <span class="fa fa-angle-double-right" ></span>
  </a>
</div>




</div>
    </div>
</div>



</>);


}

export default prod;