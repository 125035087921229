
import Suman from './team photos/sumansir.png';
import Rohan from './team photos/rohan.png';
import Ridhima  from './team photos/Ridhima.jpg';
import Radhika from './team photos/radhika.jpg';
import Vikas from './team photos/vikas.jpeg';
import Shreya from './team photos/shreya.png';
import Mohit from './team photos/Mohit.jpg';
import Rohit from './team photos/Rohit.jpg';
import Ranjit from './team photos/Ranjit.jpg';
import Ashima from './team photos/ashima.jpeg';

import Khurshid from './team photos/khurshid.jpeg';
import Arun from './team photos/arun.jpg';
import Anushka from './team photos/anushka.jpg';
import Debo from './team photos/debo.png';
import Bhaskar from './team photos/bhaskar.jpeg';
import Upender from './team photos/upender.jpeg';


 const products = [



   {
    first:{
      img:Ashima,
      name:"Ashima Rattan",
      designation:"Marketing Impact Manager"
    },
    second:{
        img:Rohan,
      name:"Rohan Kumar",
      designation:"Brand & Product Manager"
    },
    third:{
      img:Ridhima,
      name:"Ridhima",
      designation:"Firmware Engineer"
    },
    fourth:{
      img:Radhika,
      name:"Radhika Raina",
      designation:"RF Engineer"
    },

   },

   
   {
    fourth:{
img:Upender,
      name:"Upendra ",
      designation:"Web Manager"

    },
    first:{
   img:Bhaskar,
      name:"Bhaskar chaturvedi",
      designation:"Mechanical designer"

    },
    second:{

        img:Vikas,
      name:"Vikas Malav",
      designation:"PCB Engineer"

    },
    third:{

      img:Khurshid,
      name:"Mohd Khurshid Alam",
      designation:"Technical Sales Manager"
    },



   }
/*
   {
    first:{
      img:Bhaskar,
      name:"Bhaskar chaturvedi",
      designation:"Mechanical designer"
    },
    second:{
        img:Upender,
      name:"Upendra ",
      designation:"Web Manager"
    },
    third:{
      img:Rohan,
      name:"Rohan Kumar",
      designation:"Brand & Product Manager"
    },
    fourth:{
      img:Vikas,
      name:"Vikas Malav",
      designation:"PCB Engineer"
    },

   }

   */
  


];


export default products;
