import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router,Route } from 'react-router-dom';
import axios from 'axios';
import Topheader from '../top_header';
import Footer from '../Footer/footer2';
import './confirmation.scss';
import Success from './success.png';
import Beacon from './beacon2.png';

class Confirmation extends React.Component {
  state = {
    loader:true,
        order_id: new URLSearchParams(this.props.location.search).get("order_id"),
        billing_name: new URLSearchParams(this.props.location.search).get("billing_name"),
        billing_address: new URLSearchParams(this.props.location.search).get("billing_address"),
        billing_city: new URLSearchParams(this.props.location.search).get("billing_city"),
        billing_state:new URLSearchParams(this.props.location.search).get("billing_state"),
        billing_zip:new URLSearchParams(this.props.location.search).get("billing_zip"),
        amount:new URLSearchParams(this.props.location.search).get("amount"),
        order_status:new URLSearchParams(this.props.location.search).get("order_status"),
        prods:[],
        firstProduct:''
      };
componentDidMount = () => {
    //console.log(new URLSearchParams(this.props.location.search).get("amount"));
    this.getCart();
  }

  getCart = () => {
    axios({
        url: 'https://api.scratchnest.com/orders',
        method: 'GET',
        withCredentials:true
        //data: payload
      })
      .then((res) => { 
        const prods = res.data.user.orders.current_order[res.data.user.orders.current_order.length-1].products;
        this.setState({prods:prods})
        this.setState({firstProduct:this.state.prods[0].title});
        
      }).catch((err) => {
        console.log(err);
        
      });
  }
//function Confirmation(){
  cartprod = (prods) => {
    if (prods.length) {
return prods.map((post, i) => (
    /* thses are the real igtems from database */

      <div className="row" id="items" key={i}>
        <div className="col-sm-4 col-md-4 col-5">
          <img src={post.imageUrl} onClick={()=>{this.props.history.push(post.path)}}/>
        </div>
        <div className="col-sm-8 col-md-8 col-7">
          <h1> {post.title} </h1>
          <p> {post.description}</p>

          {/* desktop listing of products */}
          <div id="desktop">
            <div className="form-group- form-inline" >
              <label id="itemquantity">Qty:</label>
             
 {/* here is the new button gruoup to increment ans decrement the items quantity*/}
 <div className="form-group- form-inline" >
 
                  <p>{post.qty} </p></div>
{/*------------------------------------------------------------*/}
            </div>
            <table>
              <tr>
                <td>
                  <h2>Price: <span>( <del>₹ {post.price + (post.price / 10)} </del>) </span>  <span id="span2">₹ {post.price} </span>  </h2>
                </td>
              </tr>
            </table>
          </div>
        </div></div>))}}
    
    render() {
        return (

<>    
    <Topheader/>
<div id="confirmation">

<div className="container-fluid">
 <div id="div1">
          <h1> Hi {this.state.billing_name}</h1><br></br>
 <h2> <img src={Success} /> Your Order is Confirmed</h2> <br></br>
 {this.state.prods.length>1?<p>Thanks for shopping! Your order for {this.state.firstProduct}and {this.state.prods.length-1} more items are successfully placed. We’ll send you an email regarding the same.</p>:
 <p>Thanks for shopping! Your order for {this.state.firstProduct} is successfully placed. We’ll send you an email regarding the same.</p>}
    </div>

<div className="row" id="row1">
{/* order id  ------------------------------------------------------------------*/}
<div className="col-sm-4 col-md-4 col-12">
<div id="div2">
 <h2>  Order : <span>{this.state.order_id}</span></h2> <br></br>
 <button className="btn btn-success" onClick={()=>{this.props.history.push('/profile')}}> View or Manage Order</button>
    </div>


</div>
{/* here we have added the selected address for delivery ------------------------------------------------------------------*/}

<div className="col-sm-4 col-md-4 col-12">
<div id="div3">
 <h2>  Order Will Be send to </h2> 
<p> {this.state.billing_address} <br></br>{this.state.billing_city}<br></br>{this.state.billing_state}<br></br>{this.state.billing_zip}</p>
    </div>


    
</div>

{/* order details starts here ------------------------------------------------------------------*/}
<div className="col-sm-4 col-md-4 col-12">

<div id="div4">
<table >
<tr>
    <td colSpan={2}> <h1> Order Details</h1></td>
    </tr>
    <tr>
        <td id="left" style={{"color":"grey"}}>Bag Details</td> <td id="right"> ₹ {((this.state.amount-199)*10)/9}</td>
        </tr>
        <tr>
        <td id="left" style={{"color":"grey"}}>Bag Discounts</td> <td id="right"> <span style={{"color":"grey"}}> -₹ {(this.state.amount-199)/9}</span></td>
        </tr>
        <tr>
        <td id="left" style={{"color":"grey"}}>Delivery</td> <td id="right"> <span style={{"color":"grey"}}> ₹ 199</span></td>
        </tr>
        <tr>
        <td id="left">Total Amount:</td> <td id="right"> <span > {this.state.amount}</span></td>
        </tr>
        </table>
</div>
    
</div>

</div>
{/* list of items starts here ------------------------------------------------------------------*/}
<div id="list">

<div id="heading">
    <h1> Order Summary ({this.state.prods.length} items)</h1>
    {/*<p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>*/}
</div>
{this.cartprod(this.state.prods)}
{/* sample code for item , later we will add an array here  ------------------------------------------------------------------*/}

{/* saple item fo product end herer ------------------------------------------------------------------*/}


</div>





</div>





</div>
<Footer/>
    </>);
}

}


export default Confirmation;