


import './oursectors.scss';
function OurSectors(){
return (

    <div className="oursectors" >

    <h1> Our Sectors</h1>
    <p> These are the major trending sectors of ScratchNest. </p>


    </div>

);
}


export default OurSectors;