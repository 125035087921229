import React from "react";
import { Fade } from "react-slideshow-image";
import "./carousel2.scss";
import "react-slideshow-image/dist/styles.css";
import img1 from './img1.png';
import img2 from './img2.png';
import img3 from './img3.png';
import img4 from './img4.png';
import img5 from './img5.png';
import { useHistory } from "react-router";

const fadeImages = [
  "https://res.cloudinary.com/dpysmqax5/image/upload/v1619647956/5_dwtxbb.png",
  "https://res.cloudinary.com/dpysmqax5/image/upload/v1619647960/4_uabjrd.png",
  "https://res.cloudinary.com/dpysmqax5/image/upload/v1619647963/3_oiyhwb.png",
];

const Carousel2 = () => {

  var history=useHistory();
  return (


<div id="applications_blogs">

<div id="applicationsheading" onClick={()=>{history.push("/blog")}}>
<h1 > <span> BLOGS </span> </h1>
<p> Check out our blogs on some interesting topics!</p>
</div>



<div id="demo_ambitag" class="carousel carousel-fade slide carouseldesktop" data-ride="carousel">

 
  

  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="row">
<div className="col-sm-6 col-md-6 col-xs-12">
<div class="card" onClick={()=>{history.push("/blog/16_Reasons_to_Have_Data_Loggers_for_Temperature_Logging")}}>
  <div >       <img src={img1} alt="Los Angeles" id="img" />
   </div>
  <div class="carousel_div"> 
<h1>16 Reasons to Have Data Loggers for Temperature Logging</h1>
<p>Long gone are the days of constantly worrying about storing goods when you have...

</p>

  </div>
</div>

</div>

<div className="col-sm-6 col-md-6 col-xs-12">
<div class="card" onClick={()=>{history.push("/blog/AmbiTag_Temperature_Data_Loggers_Features_And_Usages")}}>
  <div >       <img src={img2} alt="Los Angeles" id="img" />
   </div>
  <div class="carousel_div"> 
<h1>AmbiTag Temperature Data Loggers: Features And Usages</h1>
<p>Wondering about which temperature data logger to opt for? ScratchNest introduces...</p>

  </div>
</div>

</div>




      </div>


    </div>
    <div class="carousel-item">

    <div class="row">



    <div className="col-sm-6 col-md-6 col-xs-12">
<div class="card" onClick={()=>{history.push("/blog/The_Applications_of_AmbiTag_Data_Loggers_A_Detailed_Analysis")}}>
  <div >       <img src={img3} alt="Los Angeles" id="img" />
   </div>
  <div class="carousel_div"> 
<h1>The Applications of AmbiTag Data Loggers: A Detailed Analysis</h1>
<p> Let us have a look at all the applications of AmbiTag data loggers in this article. I will...</p>

  </div>
</div>

</div>






<div className="col-sm-6 col-md-6 col-xs-12">
<div class="card" onClick={()=>{history.push("/blog/Applications_of_RFID_How_It_Works_And_The_Usages")}}>
  <div >       <img src={img4} alt="Los Angeles" id="img" />
   </div>
  <div class="carousel_div"> 
<h1>Applications of RFID: How It Works And The Usages</h1>
<p> Long gone are the days of constantly worrying about storing goods when you have...</p>

  </div>
</div>

</div>







      </div>


    </div>




    <div class="carousel-item">

<div class="row">










<div className="col-sm-6 col-md-6 col-xs-12">
<div class="card" onClick={()=>{history.push("/blog/Top5_Devices_That_Use_NFC_Its_Features_And_Usages")}}>
<div >       <img src={img5} alt="Los Angeles" id="img" />
</div>
<div class="carousel_div"> 
<h1>Top 5 Devices That Use NFC: Its Features And Usages</h1>
<p>Long gone are the days of constantly worrying about storing goods when you have...</p>

</div>
</div>

</div>

<div className="col-sm-6 col-md-6 col-xs-12">
<div class="card" onClick={()=>{history.push("/blog/16_Reasons_to_Have_Data_Loggers_for_Temperature_Logging")}}>
  <div >       <img src={img1} alt="Los Angeles" id="img" />
   </div>
  <div class="carousel_div"> 
<h1>16 Reasons to Have Data Loggers for Temperature Logging</h1>
<p>Long gone are the days of constantly worrying about storing goods when you have...

</p>

  </div>
</div>

</div>

  </div>


</div>
   
   
  </div>
  
  <a class="carousel-control-prev" href="#demo_ambitag" data-slide="prev">
    <span class="fa fa-arrow-circle-left" style={{"fontSize":"30px"}}></span>
  </a>
  <a class="carousel-control-next" href="#demo_ambitag" data-slide="next">
    <span class="fa fa-arrow-circle-right" style={{"fontSize":"30px"}}></span>
  </a>
</div>



<div id="demo2" class="carousel carousel-fade slide carouselphone" data-ride="carousel">

  
  <div class="carousel-inner">
 

    <div class="carousel-item active">
      <div class="row">
<div className="col-sm-6 col-md-6 col-xs-12">
<div class="card" onClick={()=>{history.push("/blog/16_Reasons_to_Have_Data_Loggers_for_Temperature_Logging")}}>
  <div >       <img src={img1} alt="Los Angeles" id="img" />
   </div>
  <div class="carousel_div"> 
<h1>16 Reasons to Have Data Loggers for Temperature Logging</h1>
<p>Long gone are the days of constantly worrying about storing goods when you have...

</p>

  </div>
</div>

</div>
</div>
</div>

<div class="carousel-item ">
      <div class="row">
<div className="col-sm-6 col-md-6 col-12">
<div class="card" onClick={()=>{history.push("/blog/AmbiTag_Temperature_Data_Loggers_Features_And_Usages")}}>
  <div >       <img src={img2} alt="Los Angeles" id="img" />
   </div>
  <div class="carousel_div"> 
<h1>AmbiTag Temperature Data Loggers: Features And Usages</h1>
<p>Wondering about which temperature data logger to opt for? ScratchNest introduces...</p>

  </div>
</div>

</div>




      </div>


    </div>
    <div class="carousel-item">

    <div class="row">



    <div className="col-sm-6 col-md-6 col-12">
<div class="card" onClick={()=>{history.push("/blog/The_Applications_of_AmbiTag_Data_Loggers_A_Detailed_Analysis")}}>
  <div >       <img src={img3} alt="Los Angeles" id="img" />
   </div>
  <div class="carousel_div"> 
<h1>The Applications of AmbiTag Data Loggers: A Detailed Analysis</h1>
<p> Let us have a look at all the applications of AmbiTag data loggers in this article. I will...</p>

  </div>
</div>

</div>

</div>
</div>


<div class="carousel-item">

<div class="row">


<div className="col-sm-6 col-md-6 col-xs-12">
<div class="card" onClick={()=>{history.push("/blog/Applications_of_RFID_How_It_Works_And_The_Usages")}}>
  <div >       <img src={img4} alt="Los Angeles" id="img" />
   </div>
  <div class="carousel_div"> 
<h1>Applications of RFID: How It Works And The Usages</h1>
<p> Long gone are the days of constantly worrying about storing goods when you have...</p>

  </div>
</div>

</div>







      </div>


    </div>




    <div class="carousel-item">

<div class="row">










<div className="col-sm-6 col-md-6 col-12">
<div class="card"  onClick={()=>{history.push("/blog/Top5_Devices_That_Use_NFC_Its_Features_And_Usages")}}>
<div >       <img src={img5} alt="Los Angeles" id="img"/>
</div>
<div class="carousel_div"> 
<h1>Top 5 Devices That Use NFC: Its Features And Usages</h1>
<p>Long gone are the days of constantly worrying about storing goods when you have...</p>

</div>
</div>

</div>


  </div>

  </div>
</div>
  
  <a class="carousel-control-prev" href="#demo2" data-slide="prev">
    <span class="fa fa-arrow-circle-left" style={{"fontSize":"20px"}}></span>
  </a>
  <a class="carousel-control-next" href="#demo2" data-slide="next">
    <span class="fa fa-arrow-circle-right" style={{"fontSize":"20px"}}></span>
  </a>








</div>
</div>
  

  );
};
export default Carousel2;
