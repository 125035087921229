import Topheader from "../top_header";
import Footer from "../Footer/footer2";
import './Blog.scss';
import img1 from './img1.png';
import img2 from './img2.png';
import img3 from './img3.png';
import img4 from './img4.png';
import img5 from './img5.png';
import {Link} from 'react-router-dom';
import{useHistory} from 'react-router-dom';
import {useEffect} from 'react';
function Blog(){
    var history=useHistory();

    const script = document.getElementById("recaptcha-key");
  if (script) {
      script.remove();
  }
  const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
  if (recaptchaElems.length) {
      recaptchaElems[0].remove();
  }

    useEffect(() => {
        window.scrollTo(0, 0);
         document.title="ScratchNest Blogs";                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
         const script = document.getElementById("recaptcha-key");
         if (script) {
             script.remove();
         }
         const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
         if (recaptchaElems.length) {
             recaptchaElems[0].remove();
         }
      }, [])


return(<>
<Topheader></Topheader>
<div id="blog_page">

<div className="container">

<div className="row">

<div className="col-sm-12 col-md-12 col-12">
    <did id="heading">
        <h1> ScratchNest Blogs</h1>
    </did>
</div>

<div className="col-sm-6 col-md-4 col-12">
<div id="box">
<img src={img1} loading="lazy" />
<div>
    <h1>16 Reasons to Have Data Loggers for Temperature Logging</h1>
    <p>Long gone are the days of constantly worrying about storing goods when you have...</p>
    <button className="btn btn-primary" onClick={()=>{history.push("/blog/16_Reasons_to_Have_Data_Loggers_for_Temperature_Logging")}}>Read more... </button>
</div>
</div>

</div>


<div className="col-sm-6 col-md-4 col-12">
<div id="box">
<img src={img3} loading="lazy"/>
<div>
    <h1>AmbiTag Temperature Data Loggers: Features And Usages</h1>
    <p>Wondering about which temperature data logger to opt for? ScratchNest introduces...</p>
    <button className="btn btn-primary" onClick={()=>{history.push("/blog/AmbiTag_Temperature_Data_Loggers_Features_And_Usages")}}>Read more... </button>
</div>
</div>

</div>

<div className="col-sm-6 col-md-4 col-12">
<div id="box">
<img src={img2} loading="lazy"/>
<div>
    <h1>The Applications of AmbiTag Data Loggers: A Detailed Analysis</h1>
    <p>Let us have a look at all the applications of AmbiTag data loggers in this article. I will...</p>
    <button className="btn btn-primary" onClick={()=>{history.push("/blog/The_Applications_of_AmbiTag_Data_Loggers_A_Detailed_Analysis")}}>Read more... </button>
</div>
</div>

</div>


<div className="col-sm-6 col-md-4 col-12">
<div id="box">
<img src={img4} loading="lazy"/>
<div>
    <h1>Applications of RFID: How It Works And The Usages</h1>
    <p>Long gone are the days of constantly worrying about storing goods when you have...</p>
    <button className="btn btn-primary" onClick={()=>{history.push("/blog/Applications_of_RFID_How_It_Works_And_The_Usages")}}>Read more... </button>
</div>
</div>

</div>



<div className="col-sm-6 col-md-4 col-12">
<div id="box">
<img src={img5} loading="lazy"/>
<div>
    <h1>Top 5 Devices That Use NFC: Its Features And Usages</h1>
    <p>Long gone are the days of constantly worrying about storing goods when you have...</p>
    <button className="btn btn-primary" onClick={()=>{history.push("/blog/Top5_Devices_That_Use_NFC_Its_Features_And_Usages")}}>Read more... </button>
</div>
</div>

</div>


</div>


</div>


</div>



<Footer/>
</>);

}


export default Blog;