import React from 'react';
//import { BrowserRouter as Router} from "react-router-dom";
import axios from 'axios';
import { useEffect } from 'react';
//import ReactDOM from 'react-dom';
import './Cart.scss';
import Topheader from "../top_header";
import Footer from '../Footer/footer2';
import Beacon from './beacon2.png';
import BarLoader from "react-spinners/ScaleLoader";
//import { LoadingOverlay, Loader } from 'react-overlay-loader';

//import 'react-overlay-loader/styles.css';
//import { Modal, Button, Form } from "react-bootstrap";
class Temp extends React.Component {



  state = {
    loader: true,
    id: new URLSearchParams(this.props.location.search).get("id"),
    product: [],
    user: [],
    address: [],
    qty: 1
    //date:[]
  };




  handleChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  changeShow = () => {

  }

  componentDidMount = () => {
    const script = document.getElementById("recaptcha-key");
  if (script) {
      script.remove();
  }
  const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
  if (recaptchaElems.length) {
      recaptchaElems[0].remove();
  }
    this.getproduct();
    this.getUser()
  }

  //select address functionality
  onChange = i => {
    this.setState({
      street_addTitle: this.state.address[i].street_add,
      stateTitle: this.state.address[i].state,
      cityTitle: this.state.address[i].city,
      pinTitle: this.state.address[i].pin,
      checked: i
    });
  };


  //add address from submit request 
  submit = (e) => {
    e.preventDefault();
    const payload = {
      street_add: this.state.street_add,
      state:this.state.state,
      city: this.state.city,
      pin: this.state.pin
    };

    axios({
      url: 'https://api.scratchnest.com/do-addAddress',
      method: 'POST',
      data: payload,
      withCredentials: true,

    }).then(() => {
      console.log('Data sent');
      this.resetUserInputs();
      this.getUser();
    }).catch((err) => {
      console.log(err);
    });
  }

  //clear the inputs after add address form submission and success response
  resetUserInputs = () => {
    this.setState({ street_add: '', state:'',city: '', pin: '' });
  }

  //get all the product information
  getproduct = async () => {

    window.scrollTo(0, 0);
    document.title = "Cart";


    try {
      axios.get('https://api.scratchnest.com/products', { withCredentials: true })
        .then((res) => {
          const prods = res.data.products;
          var product = prods.findIndex(objInItems => new String(objInItems._id).trim() == new String(this.state.id).trim());
          console.log(prods[product]);
          this.setState({ product: prods[product] });
          //this.setState({ total: total });

          if (prods) {
            this.setState({ loader: false });
          }

        })
        .catch((err) => {
          //console.log(err);
          this.setState({ loader: false });

          //const {history}=this.props;
          /*   history.push('/login');        */
        });
    } catch (err) {
      // const {history}=this.props;
      this.setState({ loader: false });

      // history.push('/login');
    }
  }

  //get all the cart information for the logged in user
  getUser = () => {
    try {
      axios.get('https://api.scratchnest.com/cart', { withCredentials: true })
        .then((res) => {
          console.log(res.data.user);
          const user = res.data.user;
          const address = res.data.user.address;
          this.setState({ user: user });
          this.setState({ address: address });
          this.setState({ street_addTitle: this.state.address[0].street_add })
          this.setState({ checked: 0 });
          this.setState({ stateTitle: this.state.address[0].state })
          this.setState({ cityTitle: this.state.address[0].city })
          this.setState({ pinTitle: this.state.address[0].pin })
          this.setState({ addId: this.state.address[0]._id })
          this.setState({ order_id: 'SNORD' + new Date().toLocaleDateString('en-GB').replace(/-|\//g, '').split("").reverse().join("") + '-' + this.state.user._id.slice(0, -16) })
          if (user) {
            this.setState({ loader: false });
          }
          //this.getproduct()
        }).catch((err) => {
          console.log(err);
          // const { history } = this.props;
          // history.push('/login');
        })
    }
    catch (err) {
      console.log(err);
      // const { history } = this.props;
      // history.push('/login');
    };
  }

  createSelectItems = () => {
    let items = [];
    for (let i = 1; i <= 10; i++) {
      items.push(<option key={i} value={i}>{i}</option>);
      //here I will be creating my options dynamically based on
      //what props are currently passed to the parent component
    }
    return items;
  }

  onDropdownSelected = (e) => {
    this.setState({ qty: e.target.value })
    //here you will see the current selected value of the select input
  }

  /* the ordered item codeis below*/

  //from here we are listing the different address
  useraddress = (address) => {
    if (address.length && this.state.user.contact_no != '-') {
      /* return (
         <div>
           <label className="form-check-label" style={{ "marginTop": "5px" }}>
             <input type="radio" className="form-check-input" id="radio1" name="optradio" value="option1" checked />
 
             this is demo address<br />demo city <br /> demo pin
           </label>
         </div>
       );*/
      return address.map((add, i) => (
        <div>
          <label className="form-check-label" style={{ "marginTop": "5px" }} key={i}>
            <input type="radio" className="form-check-input"
              checked={this.state.checked === i ? true : false} key={i}
              name="deliver" value={this.state.address[i]._id} onChange={this.onChange.bind(this, i)} />
            {add.street_add}<br />{add.state}<br />{add.city}<br />{add.pin}
          </label>
        </div>

      ));
    }
  };

  //from here return starts
  render() {
    var checkoutButton;
    //console.log(this.state.user);
    if (this.state.user.length == 0) {
      checkoutButton = <button className="btn btn-primary" onClick={() => { this.props.history.push("/login") }} id="checkoutbtn" >Proceed to Checkout</button>
    } else if (this.state.user.contact_no == '-') {
      checkoutButton = <button className="btn btn-primary" onClick={() => { this.props.history.push("/editprofile") }} id="checkoutbtn" >Proceed to Checkout</button>
    } else {
      checkoutButton = <form method="POST" name="customerData" action="https://api.scratchnest.com/ccavRequestHandler">
        <input type="hidden" name="merchant_id" id="merchant_id" value="271474" readOnly='true' />
        <input type="hidden" name="order_id" value={this.state.order_id} readOnly='true' />
        <input type="hidden" name="currency" value="INR" />
        <input type="hidden" name="amount" value={((this.state.product.price * this.state.qty) - (this.state.product.price * this.state.qty / 10)) + 199} readOnly='true' />
        <input type="hidden" name="redirect_url"
          value="https://api.scratchnest.com/ccavResponseHandler" />
        <input type="hidden" name="cancel_url"
          value="https://api.scratchnest.com/ccavResponseHandler" />
        <input type="hidden" name="language" id="language" value="EN" />
        <input type="hidden" name="billing_name" value={this.state.user.name} />
        <input type="hidden" name="billing_address" value={this.state.street_addTitle} />
        <input type="hidden" name="billing_city" value={this.state.cityTitle} />
        <input type="hidden" name="billing_state" value={this.state.stateTitle} />
        <input type="hidden" name="billing_zip" value={this.state.pinTitle} />
        <input type="hidden" name="billing_country" value="India" />
        <input type="hidden" name="billing_tel" value={this.state.user.contact_no} />
        <input type="hidden" name="billing_email" value={this.state.user.email} />
        <input type="hidden" name="delivery_name" value={this.state.user.name} />
        <input type="hidden" name="delivery_address" value={this.state.street_addTitle} />
        <input type="hidden" name="delivery_city" value={this.state.cityTitle} />
        <input type="hidden" name="delivery_state" value={this.state.stateTitle} />
        <input type="hidden" name="delivery_zip" value={this.state.pinTitle} />
        <input type="hidden" name="delivery_country" value="India" />
        <input type="hidden" name="delivery_tel" value={this.state.user.contact_no} />
        <input type="hidden" name="merchant_param1" value={this.state.user._id} />
        <input type="hidden" name="merchant_param2" value={this.state.product._id} />
        <input type="hidden" name="merchant_param3" value={this.state.qty} />
        <input type="submit" value="Proceed to Checkout" id="checkoutbtn" className="btn btn-primary" />
      </form>
    }
    return (

      <>
        <Topheader />
        <div id="cart">


          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-12 heading">

                <h1> Get Your Product </h1>
                <p> Your order will be placed for {this.state.product.title} {this.state.product.category}. Here is your order summary.</p>
              </div>
              <div className="col-sm-12 col-md-8 col-12">

                {/* listing fo the items */}
                {/*this.cartprod(this.state.prods)*/}

                {/* the selected item starts here -----------------------------------------------------------------------------------*/}

                <div className="row" id="items" >
                  <div className="col-sm-4 col-md-4 col-5">
                    <img src={this.state.product.imageUrl} onClick={()=>{this.props.history.push(this.state.product.path)}}/>
                  </div>
                  <div className="col-sm-8 col-md-8 col-7">
                    <h1>{this.state.product.title}</h1>
                    <p> {this.state.product.description}</p>

                    {/* desktop listing of products */}
                    <div id="desktop">
                      <div className="form-group- form-inline" >
                        <label id="itemquantity">Qty:</label>
                        <select onChange={this.onDropdownSelected}  defaultValue={1}>
                          {this.createSelectItems()}
                        </select>
                      </div>

                      {/* here is the new button gruoup to increment ans decrement the items quantity*/}

                      {/*------------------------------------------------------------*/}
                      <table>
                        <tr>
                          <td>
                            <h2>Price:<span id="span2">₹ {this.state.product.price} </span><span>( <del>₹ {this.state.product.price + (this.state.product.price / 10)} </del>) </span>  </h2>
                          </td>
                          <td>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  {/* mobile listing of products */}
                  <div className="col-sm-0 col-md-0 col-12">
                    <div id="phone">
                      <table>
                        <tr>
                          <td>
                            <div className="form-group- form-inline" >
                              <label id="itemquantity">Qty:</label>
                              <select onChange={this.onDropdownSelected}  >
                                {this.createSelectItems()}
                              </select>
                            </div>
                          </td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <h2>Price:<span id="span2">₹ {this.state.product.price * this.state.qty} </span><span>( <del>₹ {this.state.product.price * this.state.qty + (this.state.product.price * this.state.qty / 10)} </del>) </span>  </h2>
                          </td>

                        </tr>
                      </table>
                    </div>
                  </div>

                </div>




                {/* the selected item code ends here -----------------------------------------------------------------------------------*/}






              </div>

              <div className="col-sm-12 col-md-4 col-12">


                <div className="checkout"> {/*}  style={/*{ display: this.state.prods.length ? "block" : "none" }*/}
                  <table >
                    <div >
                      <tr>
                        <td colSpan={2}> <h1> Order Details</h1></td>
                      </tr>
                      <tr>
                        <td id="left" style={{ "color": "grey" }}>Bag Details</td> <td id="right">₹ {this.state.product.price * this.state.qty}</td>
                      </tr>
                      <tr>
                        <td id="left" style={{ "color": "grey" }}>Bag Discounts</td> <td id="right"> <span style={{ "color": "grey" }}> -₹ {(this.state.product.price * this.state.qty) / 10}</span></td>
                      </tr>
                      <tr>
                        <td id="left" style={{ "color": "grey" }}>Delivery</td> <td id="right"> <span style={{ "color": "grey" }}> ₹ 199</span></td>
                      </tr>
                      <tr>
                        <td id="left">Total Amount:</td> <td id="right"> <span > ₹ {((this.state.product.price * this.state.qty) - (this.state.product.price * this.state.qty / 10)) + 199}</span></td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          {/*this.state.user?
                         <form method="POST" name="customerData" action="http://localhost:5000/ccavRequestHandler">
                           <input type="hidden" name="merchant_id" id="merchant_id" value="271474" readOnly='true'/>
                          <input type="text" name="order_id" value={this.state.order_id} readOnly='true'/>
                          <input type="hidden" name="currency" value="INR" />
                          <input type="hidden" name="amount" value={this.state.product.price} readOnly='true' />
                          <input type="hidden" name="redirect_url"
                            value="https://api.scratchnest.com/ccavResponseHandler" />
                          <input type="hidden" name="cancel_url"
                            value="https://api.scratchnest.com/ccavResponseHandler" />
                          <input type="hidden" name="language" id="language" value="EN" />
                          <input type="hidden" name="billing_name" value={this.state.user.username} />
                          <input type="hidden" name="billing_address"
                            value={this.state.street_addTitle} />
                          <input type="hidden" name="billing_city" value={this.state.cityTitle} />
                          <input type="hidden" name="billing_state" value="UP" />
                          <input type="hidden" name="billing_zip" value={this.state.pinTitle} />
                          <input type="hidden" name="billing_country" value="India" />
                          <input type="hidden" name="billing_tel" value={this.state.user.contact_no} />
                          <input type="hidden" name="billing_email" value={this.state.user.email} />
                          <input type="hidden" name="delivery_name" value={this.state.user.name} />
                          <input type="hidden" name="delivery_address"
                            value={this.state.street_addTitle} />
                          <input type="hidden" name="delivery_city" value={this.state.cityTitle} />
                          <input type="hidden" name="delivery_state" value="Maharashtra" />
                          <input type="hidden" name="delivery_zip" value={this.state.pinTitle} />
                          <input type="hidden" name="delivery_country" value="India" />
                          <input type="hidden" name="delivery_tel" value={this.state.user.contact_no} />
                          <input type="submit" value="Proceed to Checkout" className="btn btn-primary" id="checkoutbtn" />
                    </form>:(<p><button className="btn btn-primary" onClick={()=>{this.props.history.push("/login")}} id="checkoutbtn" >Proceed to Checkout</button><br/><a href="#" color="blue" >Checkout as Guest</a></p>)*/}
                          {checkoutButton}
                        </td>
                      </tr>
                    </div>
                    {this.state.user.length != 0 && this.state.user.contact_no != '-' ?
                      <tr>
                        <td colSpan={2}> <h2> Select Delivery address</h2></td>
                      </tr> : null}
                    <tr>
                      <td colSpan={2}>

                        <form className="form">
                          <div className="form-check">

                            {/* here we are listing address*/}
                            {this.useraddress(this.state.address)}
                          </div>
                        </form>
                      </td>
                    </tr>
                    <tr>
                      {this.state.user.length != 0 && this.state.user.contact_no != '-' ?
                        <td colSpan={2}> <button className="btn btn-primary" id="addaddress" data-toggle="collapse" data-target="#collapse" aria-expanded="true"
                          aria-controls="collapsePages" > Add new Address </button>
                          <div  >

                            {/* this is the form to add new address */}
                            <div id="collapse" className="collapse" style={{ "margin-top": "5%" }}>
                              <form onSubmit={this.submit}>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-12 div-col-sm-12 col-12">
                                      <label>Street Address</label>
                                      <input type="text" name="street_add" value={this.state.street_add} placeholder="Enter Street Address"
                                        onChange={this.handleChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-12 div-col-sm-12 col-12">
                                      <label>State</label>
                                      <input type="text" name="state" value={this.state.state} placeholder="Enter State"
                                        onChange={this.handleChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-12 div-col-sm-12 col-12">
                                      <label>City</label>
                                      <input type="text" name='city' value={this.state.city} placeholder="Enter City"
                                        onChange={this.handleChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-12 div-col-sm-12 col-12">
                                      <label>Pin</label>
                                      <input type="text" name="pin" value={this.state.pin} placeholder="Enter PIN"
                                        onChange={this.handleChange} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                                <button className="btn btn-outline-primary"  >Submit</button>
                              </form>
                            </div>
                          </div>
                        </td> : null}
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">

          </div>

        </div>
        <Footer />
      </>
    );
  }
}
export default Temp;
