import Topheader from '../top_header/index';
import Footer from '../Footer/footer2';
import { Profiler, useState } from 'react';
import Man from './man2.png';
import './profile.scss';
import axios from 'axios';
import React from 'react';
import Beacon from './beacon2.png';
class Profile extends React.Component {

  state = {
    loader: true,
    user: [],
    current_order: [],
    previous_order: [],
    //total: [],
    street_add: '',
    city: '',
    pin: '',
    State: '',
    number: ''
  };
  componentDidMount = () => {
    //console.log(new URLSearchParams(this.props.location.search).get("amount"));
    const script = document.getElementById("recaptcha-key");
    if (script) {
      script.remove();
    }
    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElems.length) {
      recaptchaElems[0].remove();
    }
    this.getDetail();
  }

  getDetail = () => {
    axios({
      url: 'https://api.scratchnest.com/orders',
      method: 'GET',
      withCredentials: true
      //data: payload
    })
      .then((res) => {
        console.log(res.data.user);
        const user = res.data.user;
        const current_order = res.data.user.orders.current_order;
        //const current_order_prods=res.data.user.orders.current_order
        const previous_order = res.data.user.orders.previous_order;
        //const total = res.data.user.cart.totalPrice;
        const address = res.data.user.address;
        this.setState({ user: user });
        this.setState({ current_order: current_order });
        this.setState({ previous_order: previous_order });
        //this.setState({ total: total });
        this.setState({ street_add: this.state.user.address[0].street_add })
        this.setState({ city: this.state.user.address[0].city })
        this.setState({ State: this.state.user.address[0].state })
        this.setState({ pin: this.state.user.address[0].pin })

      }).catch((err) => {
        console.log(err);
        const { history } = this.props;
        history.push('/login');
      });
  }

  currentOrder = (current_order) => {
    if (current_order.length) {
      return current_order.reverse().map((post, i) => (
        <div className="row" id="orders" key={i}>
          <table>
            <tr>
              <td>
                <h1># {post.orderId}</h1>
              </td>
              <td>
                {/*---------------------- fill the cart status Delivered or not ---------------------*/}
                <span className="badge badge-success" id="track" style={{ "fontSize": "20px" }}>Track your Order</span>

                {/*------------------------------------------*/}
              </td>
            </tr>
          </table>
          {post.products.map((ord, j) => (
            <div className="row" id="items" key={j}>
              <div className="col-sm-4 col-md-4 col-5">
                <img src={ord.imageUrl} onClick={() => { this.props.history.push(ord.path) }} />
              </div>
              <div className="col-sm-8 col-md-8 col-7">
                {/*<h1> {post.product_title} </h1>*/}
                <h1> {ord.title} </h1>
                <p> {ord.description}</p>
                <div id="desktop">
                  <div className="form-group- form-inline" >
                    <label id="itemquantity">Qty:</label>
                    {ord.qty}
                  </div>
                  <table>
                    <tr>
                      <td>
                        <h2>Price: <span>( <del>₹ {ord.price + (ord.price / 10)} </del>) </span>  <span id="span2">₹ {ord.price} </span>  </h2>
                      </td>

                      <td>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-sm-0 col-md-0 col-12">
                <div id="phone">
                  <table>
                    <tr>
                      <td>
                        <div className="form-group- form-inline" >
                          <label id="itemquantity">Qty:</label>
                          {post.qty}
                        </div>
                      </td>
                      <td>

                        {/*---------------------- fill the cart status Delivered or not ---------------------*/}

                        <span className="badge badge-primary" id="delete"> Delivered</span>


                        {/*----------------------  ---------------------*/}

                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <h2>Price: <span>( <del>₹ {post.price + (post.price / 10)}</del>) </span>  <span id="span2">₹  {post.price}</span>  </h2>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          ))}</div>))
    } else {
      return <p>No orders</p>
    }
  }

  prevOrder = (previous_order) => {
    if (previous_order.length) {
      return previous_order.products.map((post, i) => (
        post.status == true ?
          (<div className="row" id="orders" key={i}>
            <table>
              <tr>
                <td>
                  <h1># {post.orderId}</h1>
                </td>
                <td>
                  {/*---------------------- fill the cart status Delivered or not ---------------------*/}
                  <span className="badge badge-success" id="track" style={{ "fontSize": "20px" }}>Track your Order</span>

                  {/*------------------------------------------*/}
                </td>
              </tr>
            </table>
            {post.products.map((ord, j) => (
              <div className="row" id="items" key={i}>
                <div className="col-sm-4 col-md-4 col-5">
                  <img src={ord.imageUrl} onClick={() => { this.props.history.push(ord.path) }} />
                </div>
                <div className="col-sm-8 col-md-8 col-7">
                  <h1> {ord.product_title} </h1>
                  <p> {ord.description}</p>
                  <div id="desktop">
                    <div className="form-group- form-inline" >
                      <label id="itemquantity">Qty:</label>
                      {ord.qty}
                    </div>
                    <table>
                      <tr>
                        <td>
                          <h2>Price: <span>( <del>₹ {ord.price + (ord.price / 10)} </del>) </span>  <span id="span2">₹ {ord.price} </span>  </h2>
                        </td>
                        <td>

                          {/*---------------------- fill the cart status Delivered or not ---------------------*/}
                          <span className="badge badge-success" id="delete" style={{ "fontSize": "20px" }}> Deliver</span>

                          {/*-------------------------------------------*/}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-sm-0 col-md-0 col-12">
                  <div id="phone">
                    <table>
                      <tr>
                        <td>
                          <div className="form-group- form-inline" >
                            <label id="itemquantity">Qty:</label>
                            {ord.qty}
                          </div>
                        </td>
                        <td>

                          {/*---------------------- fill the cart status Delivered or not ---------------------*/}
                          <span className="badge badge-success" id="delete"> Delivered</span>

                          {/*---------------------------------------*/}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h2>Price: <span>( <del>₹ {ord.price + (ord.price / 10)}</del>) </span>  <span id="span2">₹  {ord.price}</span>  </h2>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>))}</div>) : null)
      )
    } else {
      return <p>No orders</p>
    }
  }


  render() {
    return (
      <>
        <Topheader />
        <div id="profile">

          <div className="container-fluid out">
            <div className="row">
              <div className="col-sm-4 col-md-4 col-12 " >

                <div id="left">
                  {/* -------------------------------------------------Image will be added here-------------------------------*/}
                  <i class="fa fa-user-circle-o" id="image" ></i>
                  <br></br>    <br></br>


                  <div id="data">
                    <h1> {this.state.user.name} </h1>

                    {this.state.State != '-' ? <h2><i class="fa fa-map-marker"></i>  {this.state.State} , India </h2> :
                      <h2><i class="fa fa-map-marker"></i>India </h2>}

                    <hr></hr>
                    {this.state.user.contact_no != '-' ? <h3> <i class="fa fa-phone"></i>{this.state.user.contact_no}</h3> :
                      <h3> <i class="fa fa-phone"></i>Not Provided</h3>}
                    <h4><i class="fa fa-envelope"></i>{this.state.user.email}</h4>

                    <hr></hr>
                    <h5 style={{ "color": "black", "fontWeight": "bold" }}> My Address:</h5>
                    {this.state.street_add != '-' ? <h5>{this.state.street_add}</h5> : <h5>No Data Provided</h5>}
                    {this.state.city != '-' ? <h5>{this.state.city}</h5> : null}
                    {this.state.city != '-' ? <h5> PinCode-{this.state.pin}</h5> : null}
                    <br></br>
                    <br></br>
                    <button className="btn btn-primary" onClick={() => { this.props.history.push("/editprofile") }}>Edit Profile Details</button>
                  </div>
                </div>
              </div>
              <div className="col-sm-8 col-md-8 col-12 right">

                <div id="list">

                  <div id="heading">
                    <h1> Your Profile </h1>
                  </div>
                  <br></br>


                  <div id="heading">
                    <p> Your Orders ({this.state.current_order.length}) </p>
                  </div>
                  {/* sample code for item , later we will add an array here  ------------------------------------------------------------------*/}

                  {/*<div className="row" id="items">

<div className="col-sm-4 col-md-4 col-5">

<img src={Beacon} />
</div>

<div className="col-sm-8 col-md-8 col-7">
    <h1> Tracking Beacon SN-TB101 </h1>
    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    <div id="desktop">
    <div className="form-group- form-inline" >
  <label id="itemquantity">Qty:</label>
  <select className="form-control" >
    <option>1</option>
 
  </select>
    </div>
<table>
    <tr>
        <td>
<h2>Price: <span>( <del>₹ 2620 </del>) </span>  <span id="span2">sdfsdf</span>  </h2>
</td>
<td>

</td>
</tr>
</table>
    </div>
    </div>

<div className="col-sm-0 col-md-0 col-12">
<div id="phone">
<table>
    <tr>
        <td>
    <div className="form-group- form-inline" >
  <label id="itemquantity">Qty:</label>
  <select className="form-control" >
    <option>1</option>

  </select>
</div>
</td>
<td>

</td>
</tr>
    <tr>
        <td colSpan={2}>
<h2>Price: <span>( <del>₹ 2620 </del>) </span>  <span id="span2">₹ 2629</span>  </h2>
</td>

</tr>
</table>
</div>
</div>

    </div>
    <br></br>*/}
                  {/*<button className="btn btn-outline-primary" onClick={ this.setState({display:true})}>Show More</button>*/}


                  {this.currentOrder(this.state.current_order)}
                  {/* saple item fo product end herer ------------------------------------------------------------------*/}


                </div>




                {/* This is the section for previous orders ------------------------------------------------------------------*/}

                <div id="list">


                  <br></br>

                  <div id="heading">
                    <p> Previous Orders ({this.state.previous_order.length})</p>
                  </div>
                  {/* sample code for item , later we will add an array here  ------------------------------------------------------------------*/}
                  {this.prevOrder(this.state.previous_order)}
                  {/*<div className="row" id="items">

                    <div className="col-sm-4 col-md-4 col-5">

                      <img src={Beacon} />
                    </div>


                    <div className="col-sm-8 col-md-8 col-7">
                      <h1> Tracking Beacon SN-TB101 </h1>
                      <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                      <div id="desktop">
                        <div className="form-group- form-inline" >
                          <label id="itemquantity">Qty:</label>
                          <select className="form-control" >
                            <option>1</option>

                          </select>
                        </div>
                        <table>
                          <tr>
                            <td>
                              <h2>Price: <span>( <del>₹ 2620 </del>) </span>  <span id="span2">sdfsdf</span>  </h2>
                            </td>
                            <td>

                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div className="col-sm-0 col-md-0 col-12">
                      <div id="phone">
                        <table>
                          <tr>
                            <td>
                              <div className="form-group- form-inline" >
                                <label id="itemquantity">Qty:</label>
                                <select className="form-control" >
                                  <option>1</option>

                                </select>
                              </div>
                            </td>
                            <td>

                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <h2>Price: <span>( <del>₹ 2620 </del>) </span>  <span id="span2">₹ 2629</span>  </h2>
                            </td>

                          </tr>
                        </table>
                      </div>
                    </div>

  </div>*/}
                  <br></br>
                  {/*<button className="btn btn-outline-primary" onClick={ this.setState({display2:!this.state.display2})}>Show More</button>*/}

                  {/* sample item fo product end herer ------------------------------------------------------------------*/}


                </div>










              </div>

            </div>
          </div>

        </div>


        <Footer />
      </>
    );
  }
}


export default Profile;
