import Topheader from "../top_header";

import Footer from '../Footer/footer2';
import './store.scss';
import Items from './items';
import OurItems from './Ouritems';
import {useState , useEffect} from 'react';
import img1 from './img1.png';
import customer from './customer.png';
import Store2 from './Store';
function Store(){

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Store";                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
      }, [])
    
    var [search,setSearch]=useState(false);
    var [text,setText]=useState('');
    var [found,setFound]=useState(false);
    var [i1,setI1]=useState(false);
    var [i2,setI2]=useState(false);
    
    
const ShowAll=()=>{
    
    setSearch(false);
    setText('');
    
}
const handleChange=(x)=>{
setSearch(true);

setText(x);

setFound(false);
}

const update_number=()=>{
    console.log("running");


}
const Search_Products=OurItems.map((item=>{
    var count=0;

 if(item.Item_name.toLowerCase().indexOf(text.trim().toLowerCase())>=0  ||  item.Item_type.toLowerCase().indexOf(text.trim().toLowerCase())>=0 ){
return(
    <div className="col-sm-6 col-md-4 col-12" data-aos="zoom-out">
        <div id="box">
            <div id="img_div">
                <img src={item.image} id="img"/>
            </div>
    
    <div id="heading2">
    <div className="container-fluid">
    <div className="row row2">
        <div className="col-sm-3 col-md-3 col-3">
            <h2>{item.Item_name}</h2>
        </div>
        <div className="col-sm-1 col-md-1 col-1">
            <div id="line"></div>
        </div>
        <div className="col-sm-7 col-md-7 col-7">
            <h3>{item.Item_type} </h3>
        </div>
    </div>
    
        <div className="row row3">
        <div className="col-sm-12 col-md-12 col-12">
            <p>{item.Description}</p>
        </div>
        <div className="col-sm-12 col-md-12 col-12">
            <h4>₹ {item.price}</h4>
        </div>
        <div className="col-sm-6 col-md-6 col-6" >
        <div class="input-group">
                      
                      <span class="input-group-btn">
                          <button type="button" class="quantity-left-minus btn btn-danger btn-number button1"  data-type="minus" data-field="" >
                          <i class="fa fa-minus" ></i>
                          </button>
                      </span>
                      <input type="text" id="quantity" name="quantity" class="form-control input-number" value="1" />
                      <span class="input-group-btn">
                          <button type="button" class="quantity-right-plus btn btn-success btn-number button2" data-type="plus" data-field="">
                          <i class="fa fa-plus" ></i>
                          </button>
                      </span>
                  </div>
        </div>
      
        <div className="col-sm-6 col-md-6 col-6">
        <button className="cart_button button1" >Buy Now</button>
        </div>
        
    
        <div className="col-sm-12 col-md-12 col-12">
        <button className="cart_button button2" ><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
        </div>
    
        <div className="col-sm-12 col-md-12 col-12">
        <button className="cart_button button3" ><i class="fa fa-star"></i>Move to wishlist</button>
        </div>
        
        
    
      </div>
        
    </div>
       
    
    
    
    
    </div>
    
    
        </div>
    </div>
)
 }
 


 


 }



        ) );
    
    



    const Products=Items.map((item)=>(

<div className="col-sm-6 col-md-4 col-12" data-aos="zoom-out">
    <div id="box">
        <div id="img_div">
            <img src={item.image} id="img"/>
        </div>

<div id="heading2">
<div className="container-fluid">
<div className="row row2">
    <div className="col-sm-3 col-md-3 col-3">
        <h2>{item.Item_name}</h2>
    </div>
    <div className="col-sm-1 col-md-1 col-1">
        <div id="line"></div>
    </div>
    <div className="col-sm-7 col-md-7 col-7">
        <h3>{item.Item_type} </h3>
    </div>
</div>

    <div className="row row3">
    <div className="col-sm-12 col-md-12 col-12">
        <p>{item.Description}</p>
    </div>
    <div className="col-sm-12 col-md-12 col-12">
        <h4>₹ {item.price}</h4>
    </div>
    <div className="col-sm-6 col-md-6 col-6" >
    <div class="input-group">
                  
                  <span class="input-group-btn">
                      <button type="button" class="quantity-left-minus btn btn-danger btn-number button1"  data-type="minus" data-field="" >
                      <i class="fa fa-minus" ></i>
                      </button>
                  </span>
                  <input type="text" id="quantity" name="quantity" class="form-control input-number" value="1" />
                  <span class="input-group-btn">
                      <button type="button" class="quantity-right-plus btn btn-success btn-number button2" data-type="plus" data-field="">
                      <i class="fa fa-plus" ></i>
                      </button>
                  </span>
              </div>
    </div>
  
    <div className="col-sm-6 col-md-6 col-6">
    <button className="cart_button button1" >Buy Now</button>
    </div>
    

    <div className="col-sm-12 col-md-12 col-12">
    <button className="cart_button button2" ><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
    </div>

    <div className="col-sm-12 col-md-12 col-12">
    <button className="cart_button button3" ><i class="fa fa-star"></i>Move to wishlist</button>
    </div>
    
    

  </div>
    
</div>
   




</div>


    </div>
</div>

    ) );




    const OurProducts=OurItems.map((item)=>(

        <div className="col-sm-6 col-md-4 col-12" data-aos="zoom-out">
            <div id="box">
                <div id="img_div">
                    <img src={item.image} id="img"/>
                </div>
        
        <div id="heading2">
        <div className="container-fluid">
        <div className="row row2">
            <div className="col-sm-3 col-md-3 col-3">
                <h2>{item.Item_name}</h2>
            </div>
            <div className="col-sm-1 col-md-1 col-1">
                <div id="line"></div>
            </div>
            <div className="col-sm-7 col-md-7 col-7">
                <h3>{item.Item_type} </h3>
            </div>
        </div>
        
            <div className="row row3">
            <div className="col-sm-12 col-md-12 col-12">
                <p>{item.Description}</p>
            </div>
            <div className="col-sm-12 col-md-12 col-12">
                <h4>₹ {item.price}</h4>
            </div>
            <div className="col-sm-6 col-md-6 col-6" >
            <div class="input-group">
                          
                          <span class="input-group-btn">
                              <button type="button" class="quantity-left-minus btn btn-danger btn-number button1"  data-type="minus" data-field="" >
                              <i class="fa fa-minus" ></i>
                              </button>
                          </span>
                          <input type="text" id="quantity" name="quantity" class="form-control input-number" value="1" />
                          <span class="input-group-btn">
                              <button type="button" class="quantity-right-plus btn btn-success btn-number button2" data-type="plus" data-field="">
                              <i class="fa fa-plus" ></i>
                              </button>
                          </span>
                      </div>
            </div>
          
            <div className="col-sm-6 col-md-6 col-6">
            <button className="cart_button button1" >Buy Now</button>
            </div>
            
        
            <div className="col-sm-12 col-md-12 col-12">
            <button className="cart_button button2" ><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
            </div>
        
            <div className="col-sm-12 col-md-12 col-12">
            <button className="cart_button button3" ><i class="fa fa-star"></i>Move to wishlist</button>
            </div>
            
            
        
          </div>
            
        </div>
           
        
        
        
        
        </div>
        
        
            </div>
        </div>
        
            ) );
        
        





    return(<>
    <Topheader/>
<div id="iotlab_product">
    <div className="container">
        <div className="row">
<div className="col-sm-8 col-md-6 col-12" id="input">
<input type="text"  placeholder="Search for Products..." onChange={(e)=>{handleChange(e.target.value)}}  value={text}/> 

</div>

</div>
    </div>


<div className="container-fluid div2" >
    <div className="row">
<div className="col-sm-4 col-md-4 col-12">


<div id="iotlab_img" class="carousel  slide" data-ride="carousel">

  <ul class="carousel-indicators">
    <li data-target="#iotlab_img" data-slide-to="0" class="active"></li>
    <li data-target="#iotlab_img" data-slide-to="1"></li>
    <li data-target="#iotlab_img" data-slide-to="2"></li>
  </ul>
  
  
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img1} alt="Los Angeles"  />
    </div>
    <div class="carousel-item">
      <img src={img1} alt="Chicago"  />
    </div>
    <div class="carousel-item">
      <img src={img1} alt="New York" />
    </div>
  </div>
  
  
  <a class="carousel-control-prev" href="#iotlab_img" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" href="#iotlab_img" data-slide="next">
    <span class="carousel-control-next-icon"></span>
  </a>
</div>




</div>


<div className="col-sm-4 col-md-4 col-12">
<div className="div3">
    <h1> ScratchNest </h1>
    <h2> WIFI </h2>
    <h3> CC3220SF development kit</h3> 
    <p>Operating Voltage 2.1V to 3.6V, Data RAM size - 256 kB, Data RAM type - SRAM, ADC Resolution - 12 bit, Temperature Range - –40°C to +85°C</p>
<h4><span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span> 3.0<span> (34 reviews) </span></h4>


</div>



</div>


<div className="col-sm-4 col-md-4 col-12" >

<div className="div4">


<div className="row row3">
         
            <div className="col-sm-12 col-md-12 col-12">
                <h4>₹ 1225  <span>inc GST</span></h4>
            </div>
            <div className="col-sm-12 col-md-12 col-12">
                <p>Lorem Ipsum description</p>
                <p>Quantity:</p>
            </div>
           
            <div className="col-sm-6 col-md-6 col-6" >
           
            <div class="input-group">
                      
                          <span class="input-group-btn">
                              <button type="button" class="quantity-left-minus btn btn-danger btn-number button1"  data-type="minus" data-field="" >
                              <i class="fa fa-minus" ></i>
                              </button>
                          </span>
                          <input type="text" id="quantity" name="quantity" class="form-control input-number" value="1" />
                          <span class="input-group-btn">
                              <button type="button" class="quantity-right-plus btn btn-success btn-number button2" data-type="plus" data-field="">
                              <i class="fa fa-plus" ></i>
                              </button>
                          </span>
                      </div>
            </div>
          
            <div className="col-sm-6 col-md-6 col-6">
            <button className="cart_button button1" >Buy Now</button>
            </div>
            
        
            <div className="col-sm-12 col-md-12 col-12">
            <button className="cart_button button2" onClick={update_number}><i class="fa fa-shopping-cart"></i>  Add To Cart</button>
            </div>
        
            <div className="col-sm-12 col-md-12 col-12">
            <button className="cart_button button3" ><i class="fa fa-star"></i>Move to wishlist</button>
            </div>
            
            
        
          </div>


</div>


</div>


<div className="col-12 col-sm-12 col-md-12">
    <div id="heading">
        <h1>Description</h1>
        <p>The Wi-Fi CC3220SF development kit, a single-chip wireless microcontroller (MCU) with 1MB Flash, 256KB of RAM and enhanced security features. The CC3220SF-LAUNCHXL features on-board emulation and sensors for a full out-of-the-box experience.
</p>
    </div>
</div>



<div className="col-12 col-sm-12 col-md-12">
   
<div id="table">
    <h1> Technical Specifications</h1>
<table>

<tr>
    <td id="left"> Based On </td>
    <td id="right"> CC3220SF from Texas Instruments</td>
</tr>
<tr>
    <td id="left"> Operating Voltage </td>
    <td id="right"> 2.1 V to 3.6 V</td>
</tr><tr>
    <td id="left"> Peak calibration current </td>
    <td id="right"> 450  mA</td>
</tr><tr>
    <td id="left"> Program Memory Size</td>
    <td id="right"> 1 MB</td>
</tr>
<tr>
    <td id="left"> Program Memory Type </td>
    <td id="right">Flash</td>
</tr>
<tr>
    <td id="left"> Data RAM Size</td>
    <td id="right"> 256 kB</td>
</tr>
<tr>
    <td id="left"> Data RAM Type</td>
    <td id="right"> SRAM</td>
</tr>
<tr>
    <td id="left"> Max Clock Frequency </td>
    <td id="right"> 80 MHz</td>
</tr>
<tr>
    <td id="left"> Data Bus Width </td>
    <td id="right">32 bit </td>
</tr>


<tr>
    <td id="left">  Clock Frequency</td>
    <td id="right"> 32.768-kHz</td>
</tr>


<tr>
    <td id="left">  Interface</td>
    <td id="right">I2S (1), I2C (1), SPI (1), UART (2 </td>
</tr>


<tr>
    <td id="left">  ADC Resolution</td>
    <td id="right"> 12 bit</td>
</tr>


<tr>
    <td id="left">  Number of I/Os</td>
    <td id="right"> 27 I/O</td>
</tr>

<tr>
    <td id="left">Temperature Range  </td>
    <td id="right"> –40°C to +85°C </td>
</tr>
<tr>
    <td id="left">Length  </td>
    <td id="right"> 80mm</td>
</tr>
<tr>
    <td id="left"> Width </td>
    <td id="right"> 71mm</td>
</tr>

    </table>


</div>


</div>

{/* ----------------------------------cuastomer review code -----------------------------------------*/}

<div className="col-12 col-sm-12 col-md-12 div5">

    <h1>Customer Reviews</h1>
<div className="row row4">
    <div className="col-sm-6 col-md-6 col-12">
        <div className="card">
            <table>
                <tr>
                    <td id="img">
<img src={customer} /></td>
<td><h1> arun sharma </h1></td>
</tr>
</table>

<p><span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span> Great Product </p>

<h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</h4>
<br></br>
<h5>Posted 24 December 2021</h5>
        </div>

    </div>

    <div className="col-sm-6 col-md-6 col-12">
        <div className="card">
            <table>
                <tr>
                    <td id="img">
<img src={customer} /></td>
<td><h1> arun sharma </h1></td>
</tr>
</table>

<p><span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span> Great Product </p>

<h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</h4>
<br></br>
<h5>Posted 24 December 2021</h5>
        </div>

    </div>

    <div className="col-sm-6 col-md-6 col-12">
        <div className="card">
            <table>
                <tr>
                    <td id="img">
<img src={customer} /></td>
<td><h1> arun sharma </h1></td>
</tr>
</table>

<p><span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span> Great Product </p>

<h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</h4>
<br></br>
<h5>Posted 24 December 2021</h5>
        </div>

    </div>


    <div className="col-sm-6 col-md-6 col-12">
        <div className="card">
            <table>
                <tr>
                    <td id="img">
<img src={customer} /></td>
<td><h1> arun sharma </h1></td>
</tr>
</table>

<p><span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star"></span>
<span class="fa fa-star"></span> Great Product </p>

<h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</h4>
<br></br>
<h5>Posted 24 December 2021</h5>
        </div>

    </div>

</div>



</div>

{/* ----------------------------------cuastomer review code end here-----------------------------------------*/}


{/* ----------------------------------form code start here-----------------------------------------*/}

<div className="col-sm-12 col-md-12 col-12 review_form">

    <h1> Post a Review </h1>
<form class="form" >
<label for="email" id="star">Overall Rating:</label> <br></br>
<div class="rating ">
    
<label>
    <input type="radio" name="stars" value="1" />
    <span class="icon">★</span>
  </label>
  <label>
    <input type="radio" name="stars" value="2" />
    <span class="icon">★</span>
    <span class="icon">★</span>
  </label>
  <label>
    <input type="radio" name="stars" value="3" />
    <span class="icon">★</span>
    <span class="icon">★</span>
    <span class="icon">★</span>   
  </label>
  <label>
    <input type="radio" name="stars" value="4" />
    <span class="icon">★</span>
    <span class="icon">★</span>
    <span class="icon">★</span>
    <span class="icon">★</span>
  </label>
  <label>
    <input type="radio" name="stars" value="5" />
    <span class="icon">★</span>
    <span class="icon">★</span>
    <span class="icon">★</span>
    <span class="icon">★</span>
    <span class="icon">★</span>
  </label>

</div>
<br></br>
<div class="row">
    <div class="col-12">
    <label for="email" style={{display:i1?"block":"none"}}>Enter a Headline:</label></div>
    <div class="col-12">
    
    <input type="email" class="form-control" id="email" placeholder="Add a Headline" name="email" onMouseUp={()=>{setI1(true)}} /></div>
    </div>

    <div class="row">
    <div class="col-12">
    <label for="email" style={{display:i2?"block":"none"}}>Enter a written Review:</label></div>
    <div class="col-12">
    <textarea  className="md-textarea form-control"  minlength="10" placeholder="Add a written Review" onMouseUp={()=>{setI2(true)}}  required   /> 
</div>
    </div>
 <br></br>


    <button type="submit" class="btn btn-primary">Submit Review </button> 
  </form>
</div>





{/* ----------------------------------form code end here-----------------------------------------*/}




<div className="col-12 col-sm-12 col-md-12 div6">

    <h1>Related Products</h1>
<p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

<Store2/>
</div>

</div>


</div>








<button className="btn btn-secondary" onClick={()=>{ window.scrollTo(0, 0)}}>Back To Top</button>
     </div>
    
    <Footer/>
     </>
    );
}


export default Store;